import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { HashRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import store from './redux/store';
import { ToastContainer } from "react-toastify";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <Provider store={store}>
        {/* <DndProvider backend={HTML5Backend}> */}
          <App />
          <ToastContainer
          hideProgressBar={false}
          autoClose={false}
          newestOnTop={true}
          pauseOnFocusLoss={false}
          pauseOnHover={false}
          closeOnClick={false}
          theme="dark"
        />
        {/* </DndProvider> */}
      </Provider>
    </Router>
  </React.StrictMode>
);

reportWebVitals();
