
import React, { useEffect, useRef, useState } from "react";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  // SidebarFooter,
  SidebarContent,
} from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loadLoginSubscription } from "../../redux/serviceAction";
import { useSelector } from "react-redux";

const SideBarMenu = ({ menuCollapse, menuIconClick }) => {

  const sideBarRef = useRef(null);
  let dispatch = useDispatch();
  const {loginSubscription} = useSelector(state => state.data);

  let userName = sessionStorage.getItem("userName");

  const onClickToggle = () => {
    menuIconClick();
  }

  // #region navigate function
  const navigate = useNavigate();
  const setActiveMenuItemAndNavigate = (path) => {
    navigate(path);
  }
  // #endregion

  useEffect(() => {
    const hash = window.location.hash;
    const urlsegment = hash.substring(1);
    if (urlsegment === "/" || urlsegment === "/UNSPSC_Searcher") {
      setActiveMenuItem("UNSPSC Searcher");
    } else if (urlsegment === "/SNOMED_Searcher") {
      setActiveMenuItem("SNOMED Searcher");
    } else if (urlsegment === "/Subscription") {
      setActiveMenuItem("Subscription");
    }
    dispatch(loadLoginSubscription(userName))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const [activeMenuItem, setActiveMenuItem] = useState('UNSPSC');

  return (
    <>
      <div id="header" ref={sideBarRef} className={`${menuCollapse ? 'is_close' : 'is_open'}`}>
        <ProSidebar collapsed={menuCollapse}>
          <SidebarHeader className="hc-sidebar-header">
            <div className="logotext">
              <img src="../images/login/hc_gop_logo.svg" className='hc-logo-img' alt="" />
            </div>
            <div className="closemenu" onClick={onClickToggle}>
              {menuCollapse ? (
                <img src="../../../images/sidebar/sidebar_open.svg" alt="" />
              ) : (
                <img src="../../../images/sidebar/sidebar_close.svg" alt="" />
              )}
            </div>
          </SidebarHeader>
          <SidebarContent>
            <Menu iconShape="square">
              {(Object.keys(loginSubscription).length !== 0 && loginSubscription.PageNames.length !== 0) &&
              loginSubscription?.PageNames.map((item, index) => (
              <>{item.ProductPageName === "UNSPSC Searcher" ?
                <MenuItem active={activeMenuItem === item.ProductPageName} key={index}
                icon={<img src={activeMenuItem === item.ProductPageName ? `../../../images/sidebar/${item.ProductPageName.replace(" ", "_")}_active.svg` : `../../../images/sidebar/${item.ProductPageName.replace(" ", "_")}_inactive.svg`} alt="" />}
                onClick={() => setActiveMenuItemAndNavigate(`/${item.ProductPageName.replace(" ", "_")}`)}>UNSPSC</MenuItem>
                : item.ProductPageName === "SNOMED Searcher" ?
                <MenuItem active={activeMenuItem === item.ProductPageName} key={index}
                icon={<img src={activeMenuItem === item.ProductPageName ? `../../../images/sidebar/${item.ProductPageName.replace(" ", "_")}_active.svg` : `../../../images/sidebar/${item.ProductPageName.replace(" ", "_")}_inactive.svg`} alt="" />}
                onClick={() => setActiveMenuItemAndNavigate(`/${item.ProductPageName.replace(" ", "_")}`)}>SNOMED</MenuItem>
                : item.ProductPageName === "Subscription" &&
                <MenuItem active={activeMenuItem === item.ProductPageName} key={index}
                icon={<img src={activeMenuItem === item.ProductPageName ? `../../../images/sidebar/${item.ProductPageName.replace(" ", "_")}_active.svg` : `../../../images/sidebar/${item.ProductPageName.replace(" ", "_")}_inactive.svg`} alt="" />}
                onClick={() => setActiveMenuItemAndNavigate(`/${item.ProductPageName.replace(" ", "_")}`)}>Subscribers</MenuItem> 
              }
              </>
              ))}
            </Menu>
          </SidebarContent>
          {/* <SidebarFooter>
            <Menu iconShape="square">
              <MenuItem icon={<FiLogOut />}>Logout</MenuItem>
            </Menu>
          </SidebarFooter> */}
        </ProSidebar>
      </div>
    </>
  );
};

export default SideBarMenu;