// #region login page
export const Post_Login = "Post_Login";
// #endregion

// #region subscription page
export const Get_Subscription_List = "Get_Subscription_List";
export const Get_Country = "Get_Country";
export const Get_States = "Get_States";
export const Get_Roles = "Get_Roles";
export const Get_Pages = "Get_Pages";
export const Get_Single_Subscription = "Get_Single_Subscription";
export const Get_SunscriptionBy_User = "Get_SunscriptionBy_User";
// #endregion

// #region UNSPSC page
export const Get_Unspsc_Versions = "Get_Unspsc_Versions";
export const Get_Latest_version = "Get_Latest_version";
export const Get_Unspsc_Data = "Get_Unspsc_Data";
export const Get_Unspsc_Code_Data = "Get_Unspsc_Code_Data";
// #endregion

// #region UNSPSC page
export const Get_Snomed_Data = "Get_Snomed_Data";
export const Get_Synonym_Data = "Get_Synonym_Data";
export const Get_Parent_Data = "Get_Parent_Data";
export const Get_Children_Data = "Get_Children_Data";
// #endregion