import React from 'react';
import {
    Table, TableBody, TableCell, TableContainer, TableHead,
    TableRow, Paper
} from '@mui/material';

// #region local import
import threeDot from '../../Images/icons/edit_dropdown.svg'
import { Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

function HcTable({ columns, data, rowsPerPage, currentPage, handleEdit, onRowSelection, selectedRow }) {

    let navigate = useNavigate();

    const handleView = (id) => {
        navigate(`/Subscribers/${id}`)
    }
    return (
        <div className='table-section'>
            <TableContainer component={Paper} style={{ maxHeight: 440 }} className='custom-table'>
                <Table stickyHeader aria-label="sticky table table-responsive" >
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(rowsPerPage > 0 && data.length !== 0
                            ? data.slice((currentPage - 1) * rowsPerPage, (currentPage - 1) * rowsPerPage + rowsPerPage)
                            : data
                        ).map((row, index) => {
                            return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={index}
                                className={`${selectedRow.SubscriptionID === row.SubscriptionID && "selected-row"}`}
                                onClick={() => onRowSelection(row)}
                                >
                                    {columns.map((column) => {
                                        const value = row[column.id];
                                        if (column.id === 'action') {
                                            return (
                                                <TableCell key={column.id}>
                                                    {/* <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={() => onEdit(row)}
                                                    > */}
                                                    <Dropdown>
                                                        <Dropdown.Toggle variant=" primary" className='table-drobdown-btn' id="dropdown-basic">
                                                            <img src={threeDot} alt="" />
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu>
                                                            <Dropdown.Item onClick={() => handleEdit(row.SubscriptionID)}>Edit</Dropdown.Item>
                                                            <Dropdown.Item onClick={() => handleView(row.SubscriptionID)}>View</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                    {/* <img src={threeDot} alt="" /> */}
                                                    {/* </Button> */}
                                                </TableCell>
                                            );
                                        } else {
                                            return (
                                                <TableCell key={column.id} align={column.align}>
                                                    {column.format && typeof value === 'number' ? column.format(value) : value}
                                                </TableCell>
                                            );
                                        }
                                    })}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}

export default HcTable;