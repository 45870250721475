import React, { useEffect } from 'react';
import Pagination from 'react-bootstrap/Pagination';

function PaginationComponent({ data, page, rowsPerPage, setPage, setCurrentPage, currentPage }) {
    const totalPages = page;
    const maxPagesToShow = 7; // This is your showMax equivalent

    const handlePageChange = (pageNumber) => {
        if (pageNumber >= 1 && pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
        }
    };

    const paginationItems = [];
    const startPage = Math.max(currentPage - Math.floor(maxPagesToShow / 2), 1);
    const endPage = Math.min(startPage + maxPagesToShow - 1, totalPages);
    console.log(page, "startPage")

    // eslint-disable-next-line use-isnan
    if(endPage !== 0 || endPage !== NaN){
        for (let number = startPage; number <= endPage; number++) {
            paginationItems.push(
                <Pagination.Item key={number} active={number === currentPage} onClick={() => handlePageChange(number)}>
                    {number}
                </Pagination.Item>
            );
        }
    } else {
        paginationItems.push(
            <Pagination.Item key={startPage} active={startPage === currentPage} onClick={() => handlePageChange(startPage)}>
                {startPage}
            </Pagination.Item>
        );
    }

    useEffect(() => {
        const totalPages = Math.ceil(data.length / 20);
        setPage(totalPages)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    return (
        <Pagination>
            <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1} />
            <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
            {paginationItems}
            <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
            <Pagination.Last onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} />
        </Pagination>
    );
}

export default PaginationComponent;
