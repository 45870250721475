import React, { useEffect, useMemo, useRef, useState } from 'react'
import SideBarMenu from '../SideBarMenu/SideBarMenu';
import { Row, Col, InputGroup, Form } from 'react-bootstrap';
import TopBarMenu from '../TopBar/TopBarMenu';
import { HCOnClickSubmitButton } from '../HCButtons/HCButtons';
import HcTable from '../HCTables/HcTable';
import { findDivHeight } from '../CommonFunction';
import PaginationComponent from '../PaginationComponent';
import AddSubscriptionModal from './AddSubscriptionModal';
import { useDispatch } from 'react-redux';
import { loadFilteredSubscriptionList, loadGetSubscriptionList, loadSingleSubscription } from '../../redux/serviceAction';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Loader from "react-spinner-loader";

function SubscriberList({ menuCollapse, menuIconClick, screenWidth }) {

    let dispatch = useDispatch();
    const mainRef = useRef(null);
    const headerRef = useRef(null);
    const searchBarRef = useRef(null);
    const paginRef = useRef(null);

    // data from redux store
    const {subscriptionList} = useSelector(state => state.data);

    const [tableDivHeight, setTableDivHeight] = useState(0);
    const [loader, setLoader] = useState(false);
    const [loaderMsg, setLoaderMsg] = useState("")

    useEffect(() => {
        setTableDivHeight(findDivHeight(mainRef, headerRef, searchBarRef, paginRef));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // #region search filter
    const [filterType, setFilterType] = useState("All");
    const [searchValue, setSearchValue] = useState("");
    const onSelectFilter = (e) => {
        setFilterType(e.target.value)
    }
    // #endregion
    const fetchSubscriprtion = () => {
        setLoader(true)
        setLoaderMsg("Please wait while loading data")
        if(filterType && searchValue){
            dispatch(loadFilteredSubscriptionList(filterType, searchValue, setLoader))
        } else {
            let userName = sessionStorage.getItem("userName");
            dispatch(loadGetSubscriptionList(userName, setLoader))
        }
    }

    useMemo(() => {
        fetchSubscriprtion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterType, searchValue])

    // #region pagination 
    const [page, setPage] = useState(0);
    const rowsPerPage = useState(20);
    const [currentPage, setCurrentPage] = useState(1);
    // #endregion

    // #region open add modal
    const [addSubscriptionModal, setAddSubscriptionModal] = useState(false)

    const openAddSubscribeModal = () => {
        setAddSubscriptionModal(true)
    }

    const closeAddSubscriptionModal = () => {
        setAddSubscriptionModal(false)
    }
    // #region

    // Columns definition for react-table

    const columns = [
        { id: 'CompanyName', label: 'Company Name', minWidth: 170 },
        { id: 'RoleName', label: 'Role', minWidth: 100 },
        {
            id: 'MobileNo',
            label: 'Mobile Number',
            minWidth: 170,
            // align: 'right',
            // format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'EmailID',
            label: 'Email ID',
            minWidth: 170,
        },
        { id: 'Address', label: 'Address', minWidth: 200 },
        { id: 'State', label: 'State', minWidth: 100 },
        { id: 'action', label: 'Action', minWidth: 100 },
    ];
    // #endregion

    // #region edit button function
    const [editDataId, setEditDataId] = useState("");
    const handleEdit = (subsribeId) => {
        setEditDataId(subsribeId)
        dispatch(loadSingleSubscription(subsribeId))
        openAddSubscribeModal();
    }
    // #endregion

    let navigate = useNavigate();
    const gotoPreviousPage = () => {
        navigate(-1)
    }

    const [selectedRow, setSelectedRow] = useState("")
    const onRowSelection = (row) => {
        setSelectedRow(row)
    }

    return (
        <>
            {/* Open modal */}
            {addSubscriptionModal && <AddSubscriptionModal addSubscriptionModal={addSubscriptionModal}
                closeAddSubscriptionModal={closeAddSubscriptionModal} editDataId={editDataId} setLoader={setLoader} setEditDataId={setEditDataId} />}
            {/* Open modal */}
            <Loader show={loader} type="box" message={loaderMsg} />
            <SideBarMenu menuCollapse={menuCollapse} menuIconClick={menuIconClick} />
            <div className='main-conent-div' ref={mainRef}>
                <Row className='main-div-row' ref={headerRef}>
                    <Col md={7}>
                        <div className='page-header-div'>
                            <div className='hc-main-header'>
                                <i className="fa fa-chevron-left" onClick={gotoPreviousPage} aria-hidden="true"></i><span>Subscribers</span>
                            </div>
                        </div>
                    </Col>
                    <Col md={5}>
                        <TopBarMenu />
                    </Col>
                </Row>
                <div className='subscriber-search-bar' ref={searchBarRef}>
                    <div className='search-input-div'>
                        <InputGroup className='search-input-group'>
                            <Form.Select aria-label="Default select example" onChange={onSelectFilter}>
                                <option value="All">All</option>
                                <option value="CompanyName">Company Name</option>
                                <option value="RoleName">Role Name</option>
                                <option value="MobileNo">Mobile</option>
                                <option value="EmailID">Email ID</option>
                            </Form.Select>
                            <Form.Control aria-label="Text input with dropdown button" onChange={(e) => setSearchValue(e.target.value)} />
                        </InputGroup>
                    </div>
                    <div className='add-subscribe-btn-div'>
                        {HCOnClickSubmitButton("Add Subscriber", openAddSubscribeModal)}
                    </div>
                </div>
                <div className='subscription-table-div' style={{ height: tableDivHeight + "%" }}>
                    <HcTable columns={columns} data={subscriptionList} rowsPerPage={rowsPerPage} currentPage={currentPage}
                    handleEdit={handleEdit} onRowSelection={onRowSelection} selectedRow={selectedRow} />
                </div>
                <div className='pagination-div' ref={paginRef}>
                    <div>
                        <h6>Display Page</h6>
                    </div>
                    <PaginationComponent data={subscriptionList} rowsPerPage={rowsPerPage} page={page} setPage={setPage}
                        setCurrentPage={setCurrentPage} currentPage={currentPage} />
                    <div>
                        <h6>Count: {subscriptionList.length}</h6>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SubscriberList;