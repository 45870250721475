import * as types from './serviceType';

const initialState = {
    // init state for subscription page
    subscriptionList: [],
    countryList: [],
    statesList: [],
    rolesList: [],
    pagesList: [],
    singleSubscription: {},
    loginSubscription: {},

    // init state for unspsc page
    unspscVersions: [],
    unspscData: [],
    unspscCodeData: {},

    // init state for unspsc page
    snomedData: [],
    synonymData: [],
    parentData: [],
    childrenData: [],

    loading: true,
};

const serviceReducers = (state = initialState, action) => {
    switch (action.type) {
        // #region login reducer
        case types.Post_Login:

        // #region Subscription reducer
        // eslint-disable-next-line no-fallthrough
        case types.Get_Subscription_List:
            return {
                ...state,
                subscriptionList: action.payload,
                loading: false
            }
        case types.Get_Country:
            return {
                ...state,
                countryList: action.payload,
                loading: false
            }
        case types.Get_States:
            return {
                ...state,
                statesList: action.payload,
                loading: false
            }
        case types.Get_Roles:
            return {
                ...state,
                rolesList: action.payload,
                loading: false
            }
        case types.Get_Pages:
            return {
                ...state,
                pagesList: action.payload,
                loading: false
            }
        case types.Get_Single_Subscription:
            return {
                ...state,
                singleSubscription: action.payload,
                loading: false
            }
        case types.Get_SunscriptionBy_User:
            return {
                ...state,
                loginSubscription: action.payload,
                loading: false
            }
        
        // #region unspsc page reducer
        case types.Get_Unspsc_Versions:
            return {
                ...state,
                unspscVersions: action.payload,
                loading: false
            } 
        case types.Get_Unspsc_Data:
            return {
                ...state,
                unspscData: action.payload,
                loading: false
            } 
        case types.Get_Unspsc_Code_Data:
            return {
                ...state,
                unspscCodeData: action.payload,
                loading: false
            } 
        
        // #region unspsc page reducer
        case types.Get_Snomed_Data:
            return {
                ...state,
                snomedData: action.payload,
                loading: false
            } 
        case types.Get_Synonym_Data:
            return {
                ...state,
                synonymData: action.payload,
                loading: false
            } 
        case types.Get_Parent_Data:
            return {
                ...state,
                parentData: action.payload,
                loading: false
            } 
        case types.Get_Children_Data:
            return {
                ...state,
                childrenData: action.payload,
                loading: false
            } 
            
        // eslint-disable-next-line no-fallthrough
        default:
            return state;
    }
}

export default serviceReducers;