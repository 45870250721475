import React, { useEffect, useState } from 'react'
import SideBarMenu from '../SideBarMenu/SideBarMenu'
import { Button, Col, Row } from 'react-bootstrap'
import TopBarMenu from '../TopBar/TopBarMenu';
import { useSelector } from 'react-redux';
import { displayProfileImage, loadSingleSubscription } from '../../redux/serviceAction';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

function ViewSubscription({ menuCollapse, menuIconClick }) {

    let { id } = useParams();
    let dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadSingleSubscription(id))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])
    const {singleSubscription} = useSelector(state => state.data);

    const [displayImage, setDisplayImage] = useState("")
    const [initStates, setInitStates] = useState("")

    useEffect(() => {
        if(Object.keys(singleSubscription).length !== 0){
            setInitStates(singleSubscription);
            displayProfileImage(singleSubscription.SubscriptionID, singleSubscription.ImageFileName)
                .then(fileURL => {
                    setDisplayImage(fileURL)
                })
                .catch(e => {
                    console.error("Error downloading file:", e);
                });  
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [singleSubscription])

    let navigate = useNavigate();
    const gotoPreviousPage = () => {
        navigate(-1)
    }

    return (
        <>
            {/* Open modal */}
            <SideBarMenu menuCollapse={menuCollapse} menuIconClick={menuIconClick} />
            <div className='main-conent-div'>
                <Row className='main-div-row'>
                    <Col md={7}>
                        <div className='page-header-div'>
                            <div className='hc-main-header'>
                                <i className="fa fa-chevron-left" onClick={gotoPreviousPage} aria-hidden="true"></i><span>Subscribers</span>
                            </div>
                        </div>
                    </Col>
                    <Col md={5}>
                        <TopBarMenu />
                    </Col>
                </Row>
                <div className='subscription-view'>
                    <Row>
                        <Col md={4} className='view-details-col'>
                            <div className="company-logo-image">
                                <img
                                    // src={(displayImage && !localImage) ? displayImage :  localImage ? localImage : profileImg}
                                    src={displayImage}
                                    alt=""
                                    className='company-img'
                                />
                            </div>
                            <div className='company-header'> 
                                <h6>{initStates.CompanyName}</h6>
                            </div>
                            <div className='detail-text'>
                                <h6>{initStates.RoleName}</h6>
                            </div>
                            <div className='detail-text'>
                                <h6>{initStates.EmailID}</h6>
                            </div>
                            <div className='detail-text'>
                                <h6>{initStates.WebsiteURL}</h6>
                            </div>
                            <div className='detail-text'>
                                <h6>{initStates.MobileNo}</h6>
                            </div>
                            <div className='detail-text'>
                                <h6>{initStates.Address}, {initStates.State}, {initStates.Country}</h6>
                            </div>
                            <div className='detail-text'>
                                <p>Subscription upto</p>
                                <Button className='date-view-btn'>{moment(new Date(initStates.SubscriptionToDate)).format("DD-MM-YYYY")}</Button>
                            </div>
                            <div className='detail-text'>
                                <h6>{initStates.SubscriptionPlan}</h6>
                            </div>
                            <div className='detail-text'>
                            {initStates && initStates.PageNames.length !== 0 && initStates.PageNames.map((page, i) => (
                            <h6 key={i}>{page.ProductPageName}</h6>
    ))}
                            </div>
                        </Col>
                        <Col md={8} className='view-details-right'>
                            <div className='devider'></div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    )
}

export default ViewSubscription
