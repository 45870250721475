export const subscriptionPlans = [
    { label: "Basic", value: "Basic" },
    { label: "Elite", value: "Elite" },
    { label: "Premium", value: "Premium" }
];

export const searchTypeOption = [
    { label: "And", value: "And" },
    { label: "Or", value: "Or" },
    { label: "Do not contain", value: "Do not contain" }
]