import React, { useState } from 'react'
import SideBarMenu from './SideBarMenu/SideBarMenu'
import { Col, Form, Row } from 'react-bootstrap'
import TopBarMenu from './TopBar/TopBarMenu'
import { HCSubmitButton } from './HCButtons/HCButtons';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import * as apiHeader from '../Const/apiHeaders';
import { loadChangePassword } from '../redux/serviceAction';
import { useNavigate } from 'react-router-dom';

function ChangePassword({ menuCollapse, menuIconClick }) {

  let dispatch = useDispatch();

    // #region password show and hide function
  const [pswdTextType, setPswdTextType] = useState("password");
  const [pswdView, setPswdView] = useState(false);
  const hideAndShow = () => {
    if (pswdView) {
      setPswdTextType("password")
    } else {
      setPswdTextType("text")
    }
    setPswdView(!pswdView);
  }
  const [pswdTextType1, setPswdTextType1] = useState("password");
  const [pswdView1, setPswdView1] = useState(false);
  const hideAndShow1 = () => {
    if (pswdView1) {
      setPswdTextType1("password")
    } else {
      setPswdTextType1("text")
    }
    setPswdView1(!pswdView1);
  }
  const [pswdTextType2, setPswdTextType2] = useState("password");
  const [pswdView2, setPswdView2] = useState(false);
  const hideAndShow2 = () => {
    if (pswdView2) {
      setPswdTextType2("password")
    } else {
      setPswdTextType2("text")
    }
    setPswdView2(!pswdView2);
  }
  // #endregion

  // #region set initial state
  const [initStates, setInitStates] = useState({
    UserName: "",
    Password: "",
    NewPassword: "",
    confirmPassword: ""
  })

  const handleInput = (e) => {
    const {name, value} = e.target;
    setInitStates({...initStates, [name]: value})
  }
  // $endregion
  let navigate = useNavigate();
  const logOut = () => {
    sessionStorage.clear();
    window.location.reload();
    navigate('/')
  }

  const changePassword = (e) => {
    e.preventDefault();
    if(!initStates.Password || !initStates.NewPassword || !initStates.confirmPassword){
        toast.warning("Please enter all fields", {autoClose: 3000});
        return;
    } else if(initStates.NewPassword !== initStates.confirmPassword){
      toast.warning("New password and confirm password should be same");
      return;
    }
    let userName = sessionStorage.getItem("userName");
    const {confirmPassword, ...newObject} = initStates;
    newObject.UserName = userName;

    console.log(newObject, "newObject")
    var requestData = {
      method: "PATCH",
      mode: "cors",
      headers: apiHeader.headerContent,
      body: JSON.stringify(newObject),
    };
    dispatch(loadChangePassword(requestData,logOut))

  }

  return (
    <>
        <SideBarMenu menuCollapse={menuCollapse} menuIconClick={menuIconClick} />
        <div className='main-conent-div'>
        <Row className='main-div-row'>
          <Col md={8}>
            <div className='page-header-btn-div'>
            </div>
          </Col>
          <Col md={4}>
            <TopBarMenu />
          </Col>
        </Row>
        <div className='change-password-form-div'>
            <Form className='change-password-form' onSubmit={changePassword}>
                <h4 className='greeting-text'>Change Password</h4>
                <div className='form-div'>
                <div className="input-div">
                <Form.Label className='login-form-lable'>Current password</Form.Label>
                <Form.Control
                  type={pswdTextType}
                  name="Password"
                  className="form-control txt-input"
                  placeholder=""
                  value={initStates.Password}
                  onChange={handleInput}
                />
                <i className={`fa fa-${pswdView ? 'eye-slash' : 'eye'}  pswd-hide-show`} onClick={hideAndShow}></i>
              </div>
              <br />
              <div className="input-div">
                <Form.Label className='login-form-lable'>New password</Form.Label>
                <Form.Control
                  type={pswdTextType1}
                  name="NewPassword"
                  className="form-control txt-input"
                  placeholder=""
                  value={initStates.NewPassword}
                  onChange={handleInput}
                />
                <i className={`fa fa-${pswdView1 ? 'eye-slash' : 'eye'}  pswd-hide-show`} onClick={hideAndShow1}></i>
              </div>
              <br />
              <div className="input-div">
                <Form.Label className='login-form-lable'>Confirm password</Form.Label>
                <Form.Control
                  type={pswdTextType2}
                  name="confirmPassword"
                  className="form-control txt-input"
                  placeholder=""
                  value={initStates.confirmPassword}
                  onChange={handleInput}
                />
                <i className={`fa fa-${pswdView2 ? 'eye-slash' : 'eye'}  pswd-hide-show`} onClick={hideAndShow2}></i>
              </div>
              <br />
              <div className='change-btn-div'>
                {HCSubmitButton("Submit")}
              </div>
                </div>
            </Form>
        </div>
        </div>
    </>
  )
}

export default ChangePassword;
