import React from 'react';
import {
    Table, TableBody, TableCell, TableContainer, TableHead,
    TableRow, Paper
} from '@mui/material';

function ConceptTable({ columns, data,
     onRowSelection, onRowDoubleClick, conceptRow }) {

    return (
        <div className='table-section'>
            <TableContainer component={Paper} style={{ maxHeight: 440 }} className='custom-table'>
                <Table stickyHeader aria-label="sticky table table-responsive" >
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{width: column.minWidth, minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((row, index) => {
                            return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={index}
                                className={`${conceptRow === index && "selected-row"}`}
                                onClick={() => onRowSelection(index)}
                                onDoubleClick={() => onRowDoubleClick(row)}
                                >
                                    {columns.map((column) => {
                                        const value = row[column.id];
                                        return (
                                                <TableCell key={column.id} align={column.align}>
                                                    {column.format && typeof value === 'number' ? column.format(value) : value}
                                                </TableCell>
                                            );
                                    })}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}

export default ConceptTable;