export const findDivHeight = (Ref1, Ref2, Ref3, Ref4) => {
    let mainHeight = 0;
    let sub1height = 0;
    let sub2height = 0;
    let sub3height = 0;
    if (Ref1.current) {
        mainHeight = Ref1.current.clientHeight;
    }
    if (Ref2.current) {
        sub1height = Ref2.current.clientHeight;
    }
    if (Ref3.current) {
        sub2height = Ref3.current.clientHeight;
    }
    if (Ref4.current) {
        sub3height = Ref4.current.clientHeight;
    }

    console.log()

    const totalPercentage = ((sub1height + sub2height + sub3height) * 100) / mainHeight;
    return 100 - (totalPercentage + 2);
}

export const findSideBarWidth = (sideBarWidth, screenWidth) => {
    const totalPercentage = ((sideBarWidth) * 100) / screenWidth;
    return 100 - (totalPercentage);
}
