import React, { useState } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { toast } from "react-toastify";

// #region local import
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { HCSubmitButton } from './Component/HCButtons/HCButtons';
import { loadResetPassword } from './redux/serviceAction';

function ResetPassword() {

  const navigate = useNavigate();
  let dispatch = useDispatch();
  const location = useLocation();

  // #region password show and hide function
  const [pswdTextType1, setPswdTextType1] = useState("password");
  const [pswdTextType, setPswdTextType] = useState("password");
  const [pswdView1, setPswdView1] = useState(false);
  const [pswdView, setPswdView] = useState(false);
  const hideAndShowPassword = () => {
    if (pswdView1) {
      setPswdTextType1("password")
    } else {
      setPswdTextType1("text")
    }
    setPswdView1(!pswdView1);
  }
  const hideAndShow = () => {
    if (pswdView) {
      setPswdTextType("password")
    } else {
      setPswdTextType("text")
    }
    setPswdView(!pswdView);
  }
  // #endregion

  // #region initialize state value

  const [initStates, setInitStates] = useState({
    UId: "",
    Password: "",
    confirmPassword: ""
  })

  const handleInput = (e) => {
    const { name, value } = e.target;
    setInitStates({ ...initStates, [name]: value })
  }
  // #endregion

  // #region login button functions
  const loginFunction = (e) => {
    e.preventDefault();
    // const { Password, confirmPassword } = initStates
    if (!initStates.Password || !initStates.confirmPassword) {
      toast.error('Please enter password and confirm password');
      return;
    } else if (initStates.Password !== initStates.confirmPassword){
      toast.error('Password and confirm password shoould be same');
      return;
    }

    const {confirmPassword, ...newObj} = initStates
    const queryParams = new URLSearchParams(location.search);
    const uid = queryParams.get('uid');

    newObj.UId =uid
    console.log(newObj)

    let requestData = {
      method: "PATCH",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(newObj),
    };
    dispatch(loadResetPassword(requestData, navigateToLogin)) 
  }
  // #endregion

  // #region navigate to dashboard
  const navigateToLogin = () => {
    navigate("/")
  }
  // #endregion

  return (
    <div style={{ height: '100%', width: "100%" }}>
      <Row className='hc-login-row'>
        <Col md={6} className='hc-login-left-col'>
          <div className='login-page-img-div'>
            <img src="../images/login/login_overflow_img1.svg" className='login-overflow1' alt="" />
            <div className='login-display-text'>
              <h3>Faster way to search all your medical needs. Login Now and Get started...</h3>
            </div>
            <div className='image-banner'>
              <div className='verticle-devider'></div>
              <img src="../images/login/login_banner.svg" className='banner-img' alt="" />
              <div className='horizontal-devider'></div>
            </div>
            <img src="../images/login/login_overflow_img2.svg" className='login-overflow2' alt="" />
          </div>
          <img src="../images/login/login_background.svg" className='login-background' alt="" />
        </Col>
        <Col md={6} className='hc-login-right-col'>
          <div className='hc-logo-div'>
            <img src="../images/login/hc_gop_logo.svg" className='hc-logo-img' alt="" />
          </div>
          <div className='login-form-div'>
            <h4 className='greeting-text'>Reset password</h4>
            <Form className='login-form' onSubmit={loginFunction}>
              <div className="input-div">
                <Form.Label className='login-form-lable'>New Password</Form.Label>
                <Form.Control
                  type={pswdTextType1}
                  name="Password"
                  className="form-control txt-input"
                  placeholder=""
                  value={initStates.Password}
                  onChange={handleInput}
                />
                <i className={`fa fa-${pswdView1 ? 'eye-slash' : 'eye'}  pswd-hide-show`} onClick={hideAndShowPassword}></i>
              </div>
              <br /><br />
              <div className="input-div">
                <Form.Label className='login-form-lable'>Confirm Password</Form.Label>
                <Form.Control
                  type={pswdTextType}
                  name="confirmPassword"
                  className="form-control txt-input"
                  placeholder=""
                  value={initStates.confirmPassword}
                  onChange={handleInput}
                />
                <i className={`fa fa-${pswdView ? 'eye-slash' : 'eye'}  pswd-hide-show`} onClick={hideAndShow}></i>
              </div>
              <div className='login-btn-div'>
                {HCSubmitButton("Submit")}
              </div>
            </Form>
            {/* <h4 className=''>Don’t have an account? <span className='create-link'>Create an Account</span></h4> */}
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default ResetPassword;