import { Button } from "react-bootstrap";

export const HCOnClickSubmitButton = (btnName, btnOnclick) => {
    return (
        <Button className="hc-submit-btn" onClick={btnOnclick}>{btnName}</Button>
    )
}

export const HCSubmitButton = (btnName) => {
    return (
        <Button className="hc-submit-btn" type="submit">{btnName}</Button>
    )
}

export const HCToggleButton = (btnName, btnOnclick, isLockVersion) => {
    return (
        <Button className={`${isLockVersion ? "hc-toggle-true-btn" : "hc-toggle-false-btn"}`}
            onClick={btnOnclick}>{btnName}</Button>
    )
}
export const HCSearchButton = () => {
    return (
        <Button className="hc-submit-btn search-btn" type="submit"
            ><i className="fa fa-search"></i></Button>
    )
}