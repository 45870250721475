import React, { useEffect, useState } from 'react';
import { DropdownButton, ButtonGroup, Dropdown } from 'react-bootstrap';
import { FiLogOut } from "react-icons/fi";

// #region Local import
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { displayProfileImage, loadLoginSubscription } from '../../redux/serviceAction';
import { useSelector } from 'react-redux';
import LogOutModal from './LogOutModal';
const ProfileTitle = ({displayImage, initStates}) => {
  return (
    <div className='btn-title'>
      <img
        src={displayImage}
        alt=""
        className='prof-img'
      />
      <div className='profile-name'>
        <h6>{initStates.CompanyName}</h6>
        <p className='m-0'>{initStates.RoleName}</p>
      </div>
    </div>
  );
};

function TopBarMenu() {

  let navigate = useNavigate();
  let dispatch = useDispatch();

  const {loginSubscription} = useSelector(state => state.data);

  let userName = sessionStorage.getItem("userName")

  // #region open logout confirmation modal
  const [openModal, setOpenModal] = useState(false)
  const openLogoutModal = () => {
    setOpenModal(true)
  }
  const closeLogoutModal = () => {
    setOpenModal(false)
  }
  // #endregion

  const logOut = () => {
    sessionStorage.clear();
    window.location.reload();
    navigate('/')
  }

  useEffect(() => {
    dispatch(loadLoginSubscription(userName))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [displayImage, setDisplayImage] = useState("");
  const [initStates, setInitStates] = useState({});
  useEffect(() => {
    if(Object.keys(loginSubscription).lengt !== 0){
      setInitStates(loginSubscription);
            displayProfileImage(loginSubscription.SubscriptionID, loginSubscription.ImageFileName)
                .then(fileURL => {
                    setDisplayImage(fileURL)
                })
                .catch(e => {
                    console.error("Error downloading file:", e);
                }); 
    }
  }, [loginSubscription])

  const navigateChangePassword = () => {
    navigate('/changePassowrd')
  }

  return (
    <div className='hc-topbar-div'>
      <div className='notify-bell'>
        {/* <i className="fa fa-bell"></i> */}
      </div>
      <div className='profile-dropdown'>
        <DropdownButton
          as={ButtonGroup}
          id={`dropdown-variants-Secondary`}
          variant={`secondary`}
          className='profile-btn'
          title={<ProfileTitle displayImage={displayImage} initStates={initStates} />} >
          {/* <Dropdown.Item eventKey="1">Profile</Dropdown.Item> */}
          <Dropdown.Item eventKey="2" onClick={navigateChangePassword}>Change Password</Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item eventKey="4" onClick={openLogoutModal}><FiLogOut /> Logout</Dropdown.Item>
        </DropdownButton>
      </div>
      {openModal && <LogOutModal openModal={openModal} closeLogoutModal={closeLogoutModal}
      logOut={logOut} />}
    </div>
  )
}

export default TopBarMenu;