import axios from "axios";

export default axios.create({
  baseURL: "http://hcapi.grihasoft.com/api/",
  //baseURL: "http://localhost:54510/api/",
  //baseURL: "http://3.15.162.0/GOPWebAPI/api/",
  //baseURL: "http://14.98.21.186:4251/api/",
  //baseURL: "http://192.168.0.200:4251/api/",
  headers: {
    "Content-type": "application/json",
  },
});
