import React, { useState } from 'react';
import { Row, Col, Form, Modal } from 'react-bootstrap';
import { toast } from "react-toastify";

// #region local import
import { HCOnClickSubmitButton, HCSubmitButton } from './Component/HCButtons/HCButtons';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { loadPostLogin, sendResetPasswordLink } from './redux/serviceAction';

function Login() {

  const navigate = useNavigate();
  let dispatch = useDispatch();

  // #region password show and hide function
  const [pswdTextType, setPswdTextType] = useState("password");
  const [pswdView, setPswdView] = useState(false);
  const hideAndShow = () => {
    if (pswdView) {
      setPswdTextType("password")
    } else {
      setPswdTextType("text")
    }
    setPswdView(!pswdView);
  }
  // #endregion

  // #region initialize state value

  const [initStates, setInitStates] = useState({
    UserName: "",
    Password: ""
  })

  const handleInput = (e) => {
    const { name, value } = e.target;
    setInitStates({ ...initStates, [name]: value })
  }
  // #endregion

  // #region login button functions
  const loginFunction = (e) => {
    e.preventDefault();
    const { UserName, Password } = initStates
    if (!UserName || !Password) {
      toast.error('Please enter username and password');
      return;
    }

    let requestData = {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(initStates),
    };
    dispatch(loadPostLogin(requestData, navigateToDashboRd, UserName)) 
  }
  // #endregion

  // #region navigate to dashboard
  const navigateToDashboRd = () => {
    navigate("/")
  }
  // #endregion

  // #region open reset password modal
  const [pswdReset, setPswdReset] = useState(false)
  const openResetPswdModal = () => {
    setPswdReset(!pswdReset)
  }
  const closeResetPswdModal = () => {
    setPswdReset(!pswdReset)
  }
  // #endregion

  // #region generate Reset link
  const generateResetLink = (e) => {
    e.preventDefault()
  }
  // #endregion

  // #region reset password
  const [userEmail, setUserEmail] = useState('');
  const sendResetLInk = () => {
    if(!userEmail){
      toast.warning("Please enter email id")
    }
    var postRequest = {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      }
    }
    dispatch(sendResetPasswordLink(userEmail, postRequest))
  }
  // #endregion

  return (
    <div style={{ height: '100%', width: "100%" }}>
      <Row className='hc-login-row'>
        <Col md={6} className='hc-login-left-col'>
          <div className='login-page-img-div'>
            <img src="../images/login/login_overflow_img1.svg" className='login-overflow1' alt="" />
            <div className='login-display-text'>
              <h3>Faster way to search all your medical needs. Login Now and Get started...</h3>
            </div>
            <div className='image-banner'>
              <div className='verticle-devider'></div>
              <img src="../images/login/login_banner.svg" className='banner-img' alt="" />
              <div className='horizontal-devider'></div>
            </div>
            <img src="../images/login/login_overflow_img2.svg" className='login-overflow2' alt="" />
          </div>
          <img src="../images/login/login_background.svg" className='login-background' alt="" />
        </Col>
        <Col md={6} className='hc-login-right-col'>
          <div className='hc-logo-div'>
            <img src="../images/login/hc_gop_logo.svg" className='hc-logo-img' alt="" />
          </div>
          <div className='login-form-div'>
            <h4 className='greeting-text'>Welcome back!</h4>
            <p className='login-sub-text'>Please sign into Continue</p>
            <Form className='login-form' onSubmit={loginFunction}>
              <div className="input-div">
                <Form.Label className='login-form-lable'>User Name</Form.Label>
                <Form.Control
                  type="text"
                  name="UserName"
                  className="form-control txt-input"
                  placeholder=""
                  value={initStates.UserName}
                  onChange={handleInput}
                />
              </div>
              <br /><br />
              <div className="input-div">
                <Form.Label className='login-form-lable'>Password</Form.Label>
                <Form.Control
                  type={pswdTextType}
                  name="Password"
                  className="form-control txt-input"
                  placeholder=""
                  value={initStates.Password}
                  onChange={handleInput}
                />
                <i className={`fa fa-${pswdView ? 'eye-slash' : 'eye'}  pswd-hide-show`} onClick={hideAndShow}></i>
              </div>
              <div className='forgot-link'>
                <h4 onClick={openResetPswdModal}>Forgot Password</h4>
              </div>
              <div className='login-btn-div'>
                {HCSubmitButton("Login")}
              </div>
            </Form>
            {/* <h4 className=''>Don’t have an account? <span className='create-link'>Create an Account</span></h4> */}
          </div>
        </Col>
      </Row>

      {/* forgot password modal */}
      {pswdReset && <Modal
        show={pswdReset}
        onHide={closeResetPswdModal}
        className=""
      >
        <div className="modal-header">
          <h6 className="modal-header-text">
            Reset Password
            {/* {editClientId ? "EDIT COMPANY" : "ADD COMPANY"} */}
          </h6>
          {/* <i className="fa fa-times" onClick={closeModal} /> */}
        </div>
        <form
          onSubmit={generateResetLink}
        >
          <div className="reset-form">
            <div className="input-div">
              <Form.Label className='login-form-lable'>Enter Email Id</Form.Label>
              <Form.Control
                type="text"
                name="userEmail"
                className="form-control txt-input"
                placeholder=""
                value={userEmail}
                onChange={(e) => setUserEmail(e.target.value)}
              />
            </div>
          </div>
          <div className="btn-submit-cancel">
            <div className='btn-save'>
              {HCOnClickSubmitButton("Submit", sendResetLInk)}
            </div>
            <div className='btn-cancel'>
              {HCOnClickSubmitButton("Cancel", closeResetPswdModal)}
            </div>
          </div>
        </form>
      </Modal>
      }
      {/* forgot password modal */}
    </div>
  )
}

export default Login;