import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Col, Form, Modal, Row } from 'react-bootstrap';
import profileImg from '../../Images/topbar/profile_img.jpg';
import { HCOnClickSubmitButton, HCSubmitButton } from '../HCButtons/HCButtons';
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { subscriptionPlans } from '../DropDownOptions';
import { useDispatch } from 'react-redux';
import { displayProfileImage, loadCountryList, loadImageSubscription, loadPageList, loadPostSubscription, loadRolesList, loadStatesList, loadUpdateSubscription } from '../../redux/serviceAction';
import { useSelector } from 'react-redux';

import * as apiHeader from '../../Const/apiHeaders';
import { toast } from 'react-toastify';

function AddSubscriptionModal(props) {

    let dispatch = useDispatch();
    let userName = sessionStorage.getItem("userName");
    let editDataId = props.editDataId;

    // #region set initial state
    const initState = {
        ImageFileName: "", TempImageFileName: "", CompanyName: "", WebsiteURL: "",
        RoleName: "", EmailID: "", MobileNo: "", Password: "",
        Address: "", Country: "", State: "", SubscriptionFromDate: "", SubscriptionToDate: "",
        SubscriptionPlan: "", PageNames: [], UserID: ""
    }
    const [initStates, setInitStates] = useState(initState)
    // #region

    // #region onload function
    useMemo(() => {
        dispatch(loadCountryList())
        dispatch(loadRolesList())
        dispatch(loadPageList())
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    // #endregion

    // data from redux store
    const {countryList} = useSelector(state => state.data);
    const {statesList} = useSelector(state => state.data);
    const {rolesList} = useSelector(state => state.data);
    const {pagesList} = useSelector(state => state.data);
    const {singleSubscription} = useSelector(state => state.data);

    // #region set value to initial state while editing data
    const [displayImage, setDisplayImage] = useState("")
    useEffect(() => {
        if(editDataId && Object.keys(singleSubscription).length !== 0){
            if(singleSubscription.Country){
                dispatch(loadStatesList(singleSubscription.Country))
            }
            singleSubscription.TempImageFileName = singleSubscription.ImageFileName
            setInitStates(singleSubscription);
            setSelectedProducts(singleSubscription.PageNames)
            setInitStates(singleSubscription);
            displayProfileImage(singleSubscription.SubscriptionID, singleSubscription.ImageFileName)
                .then(fileURL => {
                    setDisplayImage(fileURL)
                })
                .catch(e => {
                    console.error("Error downloading file:", e);
                });  
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editDataId, singleSubscription])
    // #endregion

    const [emptyFields, setEmptyFields] = useState([]);

    // #region upload image
    const hiddenFileInput = useRef(null);
    const uploadClick = () => {
        hiddenFileInput.current.click();
    };

    const [localImage, setLocalImage] = useState("");
    const [imgFile, setImgFile] = useState("");
    const handleChange = (event) => {
        var files = event.target.files;

        let currentFile = files[0];
        setImgFile(currentFile);

        const reader = new FileReader();
        reader.readAsDataURL(currentFile);
        reader.onloadend = () => {
            setLocalImage(reader.result);
        };

        let formData = new FormData();
        formData.append("File", currentFile);

        dispatch(loadImageSubscription(formData, setInitStates, initStates))

        setEmptyFields(emptyFields.filter((field) => field !== "ImageFileName"))
    };

    const [errorState, setErrorState] = useState(false)
    const handleInput = (e) => {
        const { name, value } = e.target;
        if (name === "EmailID") {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(value)) {
                // Set an error state or handle the error message visibility
                setErrorState(true);
            } else {
                // Remove error message or adjust state accordingly
                setErrorState(false);
            }
        }
        setInitStates({ ...initStates, [name]: value })
        setEmptyFields(emptyFields.filter((field) => field !== name))
        setMobileValidation(false)
    };

    const handleSelect = (val, name) => {
        if(name === "Country"){
            dispatch(loadStatesList(val))
        }
        setInitStates({ ...initStates, [name]: val })
        setEmptyFields(emptyFields.filter((field) => field !== name))
    }

    const [selectedProducts, setSelectedProducts] = useState([])
    const selectedOptions = pagesList.filter(option =>
        selectedProducts.some(product => product.ProductPageName.includes(option.value))
    );
    const handleProduct = (newValues, name) => {
        const updatedProducts = newValues.map(value => ({ [name]: value }));
        setSelectedProducts(updatedProducts);
        setEmptyFields(emptyFields.filter((field) => field !== "PageNames"))
    };
    // #endregion

    // #region save edit function
    const [mobileValidation, setMobileValidation] = useState(false);
    const saveEditData = (e) => {
        e.preventDefault();
        if(errorState){
            toast.error("Enter valid email-id", {autoClose: 3000});
        } else {
        initStates.PageNames = selectedProducts;
        if(editDataId && !imgFile){
            initStates.ImageFileName = singleSubscription.ImageFileName;
        } else{
            initStates.ImageFileName = imgFile.name;
        } 
        initStates.UserID = userName;

        console.log(initStates, "initStates")

        const excludeKeys = ['Password', "TempImageFileName"];
        const emptyArrays = Object.entries(initStates).reduce((acc, [key, value]) => {
            if (!excludeKeys.includes(key) && ((Array.isArray(value) && value.length === 0) || value === "" || value === undefined)) {
                acc.push(key);
            }
            return acc;

        }, []);

        if(emptyArrays.length !== 0){
            setEmptyFields(emptyArrays);
            return;
        }
        if(initStates.MobileNo.length < 10 || initStates.MobileNo.length > 10){
            setMobileValidation(true);
            return;
        }
        if(editDataId){
            let postRequest  = {
                method: "PUT",
                mode: "cors",
                headers: apiHeader.headerContent,
                body: JSON.stringify(initStates),
            };

            dispatch(loadUpdateSubscription(postRequest, closeModal, props.setLoader))
        } else{
            let postRequest  = {
                method: "POST",
                mode: "cors",
                headers: apiHeader.headerContent,
                body: JSON.stringify(initStates),
            };

            dispatch(loadPostSubscription(postRequest, closeModal, props.setLoader))
        } 
    }      
    }
    // #region 

    // #region close modal
    const closeModal = () => {
        props.closeAddSubscriptionModal();
        props.setEditDataId("")
    }
    // #endregion
    return (
        <div>
            <Modal
                show={props.addSubscriptionModal}
                onHide={props.closeAddSubscriptionModal}
                className="add-subscription-modal"
            >
                <div className="modal-header">
                    <h6 className="modal-header-text">
                        {editDataId ? "Edit" : "Add"} Subscription
                        {/* {editClientId ? "EDIT COMPANY" : "ADD COMPANY"} */}
                    </h6>
                    {/* <i className="fa fa-times" onClick={closeModal} /> */}
                </div>
                <form
                    onSubmit={saveEditData}
                >
                    <div className="add-edit-subscription-form">
                        <Row>
                            <Col md={6}>
                                <div className="company-logo-image mb-2">
                                    <img
                                        src={(displayImage && !localImage) ? displayImage :  localImage ? localImage : profileImg}
                                        alt=""
                                        className='company-img'
                                    />
                                    <div className='img-upload-btn-div'>
                                        <button
                                            type="button"
                                            className="btn upload-img-btn"
                                            onClick={uploadClick}
                                        >
                                            <i className="fa fa-plus" /> Upload Image
                                        </button>
                                        <input
                                            id="getFile"
                                            type="file"
                                            name="filetobase64"
                                            accept="image/*"
                                            className="btn-primary upload"
                                            ref={hiddenFileInput}
                                            onChange={handleChange}
                                            style={{ display: "none" }}
                                        />
                                    </div>
                                </div>
                                {emptyFields.includes("ImageFileName") &&
                                <Form.Label className='error-msg'>Image required</Form.Label>
}
                            </Col>
                            <Col md={6}>
                                <div className="input-div mb-2">
                                    <Form.Label className='login-form-lable'>Comany Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="CompanyName"
                                        className="form-control txt-input"
                                        placeholder=""
                                        value={initStates.CompanyName}
                                        onChange={handleInput}
                                    />
                                    {emptyFields.includes("CompanyName") &&
                                    <Form.Label className='error-msg'>Comany Name required</Form.Label>
}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <div className="input-div mb-2">
                                    <Form.Label className='login-form-lable'>Website link</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="WebsiteURL"
                                        className="form-control txt-input"
                                        placeholder=""
                                        value={initStates.WebsiteURL}
                                        onChange={handleInput}
                                    />
                                    {emptyFields.includes("WebsiteURL") &&
                                    <Form.Label className='error-msg'>Website Link required</Form.Label>
}
                                    </div>
                                <div className="input-div mb-2">
                                    <Form.Label className='login-form-lable'>Email Id</Form.Label>
                                    <Form.Control
                                        type="email"
                                        name="EmailID"
                                        className="form-control txt-input"
                                        placeholder=""
                                        value={initStates.EmailID}
                                        onChange={handleInput}
                                        required
                                        readOnly={editDataId}
                                    />
                                    {emptyFields.includes("EmailID") &&
                                    <Form.Label className='error-msg'>Email required</Form.Label>
}
{errorState && (
        <Form.Label className='error-msg'>Invalid email format</Form.Label>
    )}
                                    </div>
                                <div className="input-div mb-2">
                                    <Form.Label className='login-form-lable'>Country</Form.Label>
                                    <Select
                                        className="txt-input"
                                        classNamePrefix="react-select-list"
                                        options={countryList}
                                        placeholder="Select Country"
                                        name="Country"
                                        value={
                                            countryList.find(
                                                (option) => option.value === initStates.Country
                                            ) || ""
                                        }
                                        onInputChange={countryList.label}
                                        onChange={(data) => handleSelect(data.value, "Country")}
                                        isSearchable={true}
                                    />
                                    {emptyFields.includes("Country") &&
                                    <Form.Label className='error-msg'>country required</Form.Label>
                                    }
                                    </div>
                                <div className="input-div mb-2">
                                    <Form.Label className='login-form-lable'>Address</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="Address"
                                        className="form-control txt-input"
                                        placeholder=""
                                        value={initStates.Address}
                                        onChange={handleInput}
                                    />
                                    {emptyFields.includes("Address") &&
                                    <Form.Label className='error-msg'>Address required</Form.Label>
}
                                    </div>
                                <div className="input-div mb-2">
                                    <Row className='sub-row'>
                                        <Col md={6} className='sub-col1'>
                                            <Form.Label className='login-form-lable'>From</Form.Label>
                                            <div className="date-picker-with-icon">
                                                <DatePicker
                                                    selected={initStates.SubscriptionFromDate}
                                                    className="hc-date-picker txt-input"
                                                    id="date-range-picker"
                                                    dateFormat="dd/MM/yyyy"
                                                    onChange={(date) =>
                                                        handleSelect(date, "SubscriptionFromDate")
                                                    }
                                                    maxDate={new Date()}
                                                />
                                                <i
                                                    className="fa fa-calendar-o calander-icon"
                                                />
                                            </div>
                                            {emptyFields.includes("SubscriptionFromDate") &&
                                            <Form.Label className='error-msg'>From date required</Form.Label>
                                                }
                                            </Col>
                                        <Col md={6} className='sub-col2'>
                                            <Form.Label className='login-form-lable'>To</Form.Label>
                                            <div className="date-picker-with-icon">
                                                <DatePicker
                                                    selected={initStates.SubscriptionToDate}
                                                    className="hc-date-picker txt-input"
                                                    id="date-range-picker"
                                                    dateFormat="dd/MM/yyyy"
                                                    onChange={(date) =>
                                                        handleSelect(date, "SubscriptionToDate")
                                                    }
                                                    minDate={new Date()}
                                                />
                                                <i
                                                    className="fa fa-calendar-o calander-icon"
                                                />
                                            </div>
                                            {emptyFields.includes("SubscriptionToDate") &&
                                            <Form.Label className='error-msg'>To date required</Form.Label>
                                                }
                                            </Col>
                                    </Row>

                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="input-div mb-2">
                                    <Form.Label className='login-form-lable'>Role</Form.Label>
                                    <Select
                                        className="txt-input"
                                        classNamePrefix="react-select-list"
                                        options={rolesList}
                                        placeholder="Select Role"
                                        name="RoleName"
                                        value={
                                            rolesList.find(
                                                (option) => option.value === initStates.RoleName
                                            ) || ""
                                        }
                                        onInputChange={rolesList.label}
                                        onChange={(data) => handleSelect(data.value, "RoleName")}
                                        isSearchable={true}
                                    />
                                    {emptyFields.includes("RoleName") &&
                                    <Form.Label className='error-msg'>Role Name required</Form.Label>
                                    }
                                    </div>
                                <div className="input-div mb-2">
                                    <Form.Label className='login-form-lable'>Mobile Number</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="MobileNo"
                                        className="form-control txt-input"
                                        placeholder=""
                                        value={initStates.MobileNo}
                                        onChange={handleInput}
                                        maxLength={10}
                                    />
                                    {emptyFields.includes("MobileNo")  &&
                                    <Form.Label className='error-msg'>Mobile number required</Form.Label>
}
                                    {mobileValidation  &&
                                    <Form.Label className='error-msg'>Please enter valid mobile number</Form.Label>
}
                                    </div>
                                <div className="input-div mb-2">
                                    <Form.Label className='login-form-lable'>State</Form.Label>
                                    <Select
                                        className="txt-input"
                                        classNamePrefix="react-select-list"
                                        options={statesList}
                                        placeholder="Select State"
                                        name="State"
                                        value={
                                            statesList.find(
                                                (option) => option.value === initStates.State
                                            ) || ""
                                        }
                                        onInputChange={statesList.label}
                                        onChange={(data) => handleSelect(data.value, "State")}
                                        isSearchable={true}
                                    />
                                    {emptyFields.includes("State") &&
                                    <Form.Label className='error-msg'>State required</Form.Label>
                                    }
                                    </div>
                                <div className="input-div mb-2">
                                    <Form.Label className='login-form-lable'>Plan</Form.Label>
                                    <Select
                                        className="txt-input"
                                        classNamePrefix="react-select-list"
                                        options={subscriptionPlans}
                                        placeholder="Select plan"
                                        name="SubscriptionPlan"
                                        value={
                                            subscriptionPlans.find(
                                                (option) => option.value === initStates.SubscriptionPlan
                                            ) || ""
                                        }
                                        onInputChange={subscriptionPlans.label}
                                        onChange={(data) => handleSelect(data.value, "SubscriptionPlan")}
                                        isSearchable={true}
                                    />
                                    {emptyFields.includes("SubscriptionPlan") &&
                                    <Form.Label className='error-msg'>Plan required</Form.Label>
                                    }
                                    </div>
                                <div className="input-div mb-2">
                                    <Form.Label className='login-form-lable'>Product</Form.Label>
                                    <Select
                                        className="txt-input"
                                        classNamePrefix="react-select-list"
                                        options={pagesList}
                                        placeholder="Select product"
                                        name="ProductPageName"
                                        isMulti={true}
                                        value={selectedOptions}
                                        onInputChange={pagesList.label}
                                        onChange={(selectedOptions) => {
                                            const values = selectedOptions.map(option => option.value);
                                            handleProduct(values, "ProductPageName");
                                        }}
                                        isSearchable={true}
                                    />
                                    {emptyFields.includes("PageNames") &&
                                    <Form.Label className='error-msg'>Product required</Form.Label>
                                    }
                                    </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="btns-save-cancel">
                        <div className='btn-save'>
                            {HCSubmitButton(editDataId ? "Update" : "Save")}
                        </div>
                        <div className='btn-cancel'>
                            {HCOnClickSubmitButton("Cancel", closeModal)}
                        </div>
                    </div>
                </form>
            </Modal>
        </div>
    )
}

export default AddSubscriptionModal;