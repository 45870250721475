import React, { useMemo, useRef, useState } from 'react';
import SideBarMenu from '../SideBarMenu/SideBarMenu';
import { Row, Col, Form } from 'react-bootstrap';
import TopBarMenu from '../TopBar/TopBarMenu';
import Select from "react-select";
import { HCOnClickSubmitButton, HCSearchButton, HCToggleButton } from '../HCButtons/HCButtons';
import PaginationComponent from '../PaginationComponent';

import copyIcon from '../../Images/icons/copy_icon.svg';
import { searchTypeOption } from '../DropDownOptions';
import { getUnspscCodeData, getUnspscData, loadFilteredUNSPSCData, loadLatestVersion, loadUnspscCodeDetails, loadUnspscVersions } from '../../redux/serviceAction';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import * as apiHeader from '../../Const/apiHeaders';
import UnspscTable from './UnspscTable';
import { useNavigate } from 'react-router-dom';
import Loader from "react-spinner-loader";

function UNSPSCPage({ menuCollapse, menuIconClick }) {

  let dispatch = useDispatch();

  const mainRef = useRef(null);
  const headerRef = useRef(null);
  const filterRef = useRef(null);
  const paginRef = useRef(null);
  const segcatDiv = useRef(null);
  const segmentDiv = useRef(null);
  const categoryHead = useRef(null);

  // data from redux store
  const {unspscVersions} = useSelector(state => state.data);
  const {unspscData} = useSelector(state => state.data);
  const {unspscCodeData} = useSelector(state => state.data);

  const [loader, setLoader] = useState(false);
  const [loaderMsg, setLoaderMsg] = useState("")

  // #region lock version btn
  const [isLockVersion, setIsLockVersion] = useState(false)
  const lockTheVersion = () => {
    setIsLockVersion(!isLockVersion)
  }
  // #endregion

  // #region pagination 
  const [page, setPage] = useState(0);
  const rowsPerPage = 20;
  const [currentPage, setCurrentPage] = useState(1);
  // #endregion

  // #region set Initian states
  const initialState = {
    TableName: "", Keyword1: "", Keyword2: "",
    ANDStatus: "", ORStatus: "", DoNotContainStatus: "",
    UNSPSCCode1: "", UNSPSCCode2: "",
    UNSPSCCode3: "", UNSPSCCode4: "",
    PageNo: 1, PageSize: 10000
};

  const [initSearchValues, setInitSearchValues] = useState(initialState);

  const [optionStatus, setOptionStatus] = useState("");
  const [latestVersion, setLatestVersion] = useState("");
  // #endregion

  useMemo(() => {
    let userName = sessionStorage.getItem("userName")
    dispatch(loadUnspscVersions(userName))
    dispatch(loadLatestVersion(setInitSearchValues, initSearchValues, setLatestVersion))
    dispatch(getUnspscData([]))
    dispatch(getUnspscCodeData({}))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // #region clear filter function
  const clearFilter = () => {
    initialState.TableName = initSearchValues.TableName;
    setInitSearchValues(initialState);
    setIsLockVersion(false)
    setSelectedRow({})
    setSelectedSegment('')
    dispatch(getUnspscData([]))
    dispatch(getUnspscCodeData({}))
    setSelectedCode('');
    setSelectedText('')
  }
  // #endregion

  // #region select function
  const handleSelect = (val, name) => {
    setInitSearchValues({...initSearchValues, [name]: val})
  }

  const handleInput = (e) => {
    const {name, value} = e.target;
    if(name === "UNSPSCCode1"){
      setSegmentSearch(false)
    }
    setInitSearchValues({...initSearchValues, [name]: value})
  }
  // #endregion

  // #region handle search button
  const searchFilter = (e) => {
    e.preventDefault();
    setSelectedRow({})
    if (!isLockVersion) {
      toast.error("Please lock the version to continue..", { autoClose: 3000 });
      return;
    }
    if (
      !(
        initSearchValues.TableName &&
        (initSearchValues.Keyword1 ||
          initSearchValues.Keyword2 ||
          initSearchValues.UNSPSCCode1 ||
          initSearchValues.UNSPSCCode2 ||
          initSearchValues.UNSPSCCode3 ||
          initSearchValues.UNSPSCCode4)
      )
    ) {
      toast.error("Please enter atleast one search field", { autoClose: 3000 });
      return;
    }
    initSearchValues.DoNotContainStatus = "F"
    initSearchValues.ANDStatus = "F"
    initSearchValues.ORStatus = "F"
    if(optionStatus && optionStatus === "Do not contain"){
      initSearchValues.DoNotContainStatus = "T"
    } else {
      if (optionStatus && optionStatus === "And") {
        initSearchValues.ANDStatus = "T";
      } else if (optionStatus && optionStatus === "Or") {
        initSearchValues.ORStatus = "T";
      }
    }
    setLoader(true)
    setLoaderMsg("Please wait while loading data")
    filterDataApi(initSearchValues);
  }

  const searchFilterWithSegment = (segmentCode) => {
    initSearchValues.UNSPSCCode1 = segmentCode;
    filterDataApi(initSearchValues);
  }
  // #end region

  // #region Api call
  const filterDataApi = (SearchValues) => {
    dispatch(getUnspscCodeData({}))
    setSelectedCode('');
    setSelectedText('')
    setSelectedSegment('');
    setCurrentPage(1)
    let postRequest  = {
      method: "POST",
      mode: "cors",
      headers: apiHeader.headerContent,
      body: JSON.stringify(SearchValues),
    };
    
    dispatch(loadFilteredUNSPSCData(postRequest, setLoader))
  }
  // #endregion

  // #region table data// Sample function to generate data

  // Columns definition for react-table

  const columns = [
    { id: 'Code', label: 'Code', minWidth: 150 },
    { id: 'Category', label: 'Category', minWidth: 100 },
    
  ];
  // #endregion

  // #region onRow Seletion
  const [selectedRow, setSelectedRow] = useState({})
  const onRowSelection = (row) => {
    setSelectedRow(row);
    setSelectedSegment('');
    setSelectedCode('');
    setSelectedText('');
    dispatch(loadUnspscCodeDetails(initSearchValues.TableName, row.Code))
  }

  // const onRowDoubleClick = (code) => {
  //   setSelectedSegment('')
  //   dispatch(loadUnspscCodeDetails(initSearchValues.TableName, code))
  // }
  // #endregion

  // #region segment search checkbox function
  const [segmentSearch, setSegmentSearch] = useState(false)
  const handleSegmentSearch = () => {
    if(!segmentSearch === true){
      setInitSearchValues({...initSearchValues, "UNSPSCCode1": selectedRow.Code.substring(0, 2)});
      searchFilterWithSegment(selectedRow.Code.substring(0, 2))
    } else {
      setInitSearchValues({...initSearchValues, "UNSPSCCode1": ""});
      searchFilterWithSegment("")
    }
    
    setSegmentSearch(!segmentSearch)
  }
  // #endregion

  // #region handle segment codes
  const [selectedSegment, setSelectedSegment] = useState("");
  const [selectedClass, setSelectedClass] = useState("");
  const [selectedCode, setSelectedCode] = useState("");
  const [selectedText, setSelectedText] = useState("");
  const handleCodeRadio = (val, clsName, code, textVal) => {
    setSelectedSegment(val);
    setSelectedClass(clsName);
    setSelectedCode(code);
    setSelectedText(textVal)
  }
  // #enregion

  // #region copy text function
  const copyText = (id) => {
      // const text = document.getElementById('segCode').innerText;
      const text = document.getElementById(id).value;
      navigator.clipboard.writeText(text)
          .then(() => toast.success('Text copied!', {
            autoClose: 3000 
        }))
          .catch(err => console.error('Error copying text: ', err));
  };
  const copyDivText = () => {
      const text = document.getElementById('category-text').innerText;
      navigator.clipboard.writeText(text)
          .then(() => toast.success('Text copied!', {
            autoClose: 3000 
        }))
          .catch(err => console.error('Error copying text: ', err));
  };
  // #endregion

  let navigate = useNavigate();
    const gotoPreviousPage = () => {
        navigate(-1)
    }

  return (
    <>
      <Loader show={loader} type="box" message={loaderMsg} />
      <SideBarMenu menuCollapse={menuCollapse} menuIconClick={menuIconClick} />
      <div className='main-conent-div' ref={mainRef}>
        <Row className='main-div-row' ref={headerRef}>
          <Col md={8}>
            <div className='page-header-btn-div'>
              <div className='hc-main-header'>
                <i className="fa fa-chevron-left" onClick={gotoPreviousPage} aria-hidden="true"></i><span>UNSPSC</span>
              </div>
              <div className='filter-clear-btn'>
                {HCOnClickSubmitButton("Clear All", clearFilter)}
              </div>
            </div>
          </Col>
          <Col md={4}>
            <TopBarMenu />
          </Col>
        </Row>
        <Row className='search-row'>
          <Col md={8} className='search-row-col'>
            <div className='filter-section' ref={filterRef}>
              <Form className='filter-div' onSubmit={searchFilter}>
                <Row>
                  <Col md={5}>
                    <div className="input-div">
                      <div className='version-lable-div'>
                        <Form.Label className='filter-lable'>Version</Form.Label>
                        <Form.Label className='latest-version-lable'>Latest_Version: {latestVersion}</Form.Label>
                      </div>
                      <Select
                        className="txt-input"
                        classNamePrefix="react-select-list"
                        options={unspscVersions}
                        placeholder=""
                        name="version"
                        value={
                            unspscVersions.find(
                                (option) => option.value === initSearchValues.TableName
                            ) || ""
                        }
                        // onInputChange={subscriptionProduct.label}
                        onChange={(data) => handleSelect(data.value, "TableName")}
                        isSearchable={true}
                        isDisabled={isLockVersion}
                      />
                    </div>
                  </Col>
                  <Col md={2}>
                    {HCToggleButton("Lock", lockTheVersion, isLockVersion)}
                  </Col>
                  <Col md={5}>
                    <div className="input-div">
                      <Form.Label className='filter-lable'>UNSPSC Code</Form.Label>
                      <div className='code-filter'>
                        <div>
                          <Form.Control
                            type="text"
                            name="UNSPSCCode1"
                            className="form-control txt-input code-input"
                            placeholder=""
                            value={initSearchValues.UNSPSCCode1}
                            maxLength={2}
                            onChange={handleInput}
                          />
                        </div>
                        <div>
                          <Form.Control
                            type="text"
                            name="UNSPSCCode2"
                            className="form-control txt-input code-input"
                            placeholder=""
                            value={initSearchValues.UNSPSCCode2}
                            maxLength={2}
                            onChange={handleInput}
                          />
                        </div>
                        <div>
                          <Form.Control
                            type="text"
                            name="UNSPSCCode3"
                            className="form-control txt-input code-input"
                            placeholder=""
                            value={initSearchValues.UNSPSCCode3}
                            maxLength={2}
                            onChange={handleInput}
                          />
                        </div>
                        <div>
                          <Form.Control
                            type="text"
                            name="UNSPSCCode4"
                            className="form-control txt-input code-input"
                            placeholder=""
                            value={initSearchValues.UNSPSCCode4}
                            maxLength={2}
                            onChange={handleInput}
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col md={1}>
                    {/* {HCSearchButton(lockTheVersion)} */}
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <div className="input-div">
                      <Form.Label className='filter-lable'>Keyword</Form.Label>
                      <Form.Control
                        type="text"
                        name="Keyword1"
                        className="form-control txt-input"
                        placeholder=""
                        value={initSearchValues.Keyword1}
                        onChange={handleInput}
                      />
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="input-div">
                      <Form.Control
                        type="text"
                        name="Keyword2"
                        className="form-control txt-input"
                        placeholder=""
                        value={initSearchValues.Keyword2}
                        onChange={handleInput}
                      />
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="input-div">
                      <Select
                        className="txt-input"
                        classNamePrefix="react-select-list"
                        options={searchTypeOption}
                        placeholder=""
                        name="option"
                        value={
                          searchTypeOption.find(
                                (option) => option.value === optionStatus
                            ) || ""
                        }
                        onInputChange={searchTypeOption.label}
                        onChange={(data) => setOptionStatus(data.value)}
                        isSearchable={true}
                      />
                    </div>
                  </Col>
                  <Col md={1}>
                    <div className="input-div">
                      {HCSearchButton()}
                    </div>
                  </Col>
                </Row>
              </Form>
            </div>
            <div className='unspsc-table-section'>
              <UnspscTable columns={columns} data={unspscData} rowsPerPage={rowsPerPage} currentPage={currentPage}
               onRowSelection={onRowSelection} selectedRow={selectedRow} />
            </div>
            <div className='hc-pagination-section' ref={paginRef}>
              <div>
                <Form.Check
                      inline
                      label="Search in Selected Segment"
                      name="group1"
                      type="checkbox"
                      checked={segmentSearch}
                      id={`inline-radio-1`}
                      onChange={handleSegmentSearch}
                      disabled={Object.keys(selectedRow).length === 0}
                    />
              </div>
              {unspscData.length !== 0 && <div className='pagination-div' >
                <div>
                  <h6>Display Page</h6>
                </div>
                <PaginationComponent data={unspscData} rowsPerPage={rowsPerPage} page={page} setPage={setPage}
                  setCurrentPage={setCurrentPage} currentPage={currentPage} />
                <div>
                  <h6>Count: {unspscData.length}</h6>
                </div>
              </div>}
            </div>
          </Col>
          <Col md={4} className='search-row-col' ref={segcatDiv}>
            <div className='code-details-div'>
              <div className='code-display-section'>
                <div className='segment-section' ref={segmentDiv}>
                  <div>
                    <h6 className='filter-lable'>UNSPSC Code Details</h6>
                  </div>
                  <div className='segments-types'>
                    <div className='d-flex align-items-center'>
                      <span className='seg-box color-box'></span>
                      <span className='section-txt'>Segment</span>
                    </div>
                    <div className='d-flex align-items-center'>
                      <span className='fam-box color-box'></span>
                      <span className='section-txt'>Family</span>
                    </div>
                    <div className='d-flex align-items-center'>
                      <span className='cls-box color-box'></span>
                      <span className='section-txt'>Class</span>
                    </div>
                    <div className='d-flex align-items-center'>
                      <span className='com-box color-box'></span>
                      <span className='section-txt'>Commodity</span>
                    </div>
                  </div>
                  <div className='segment-code-txt-div'>
                    <Form.Check
                      inline
                      label=""
                      name="group1"
                      type="radio"
                      checked={selectedSegment === "segment"}
                      id={`inline-radio-1`}
                      disabled={Object.keys(unspscCodeData).length === 0}
                      onChange={() => handleCodeRadio("segment", "segment1", unspscCodeData?.SegmentCode, unspscCodeData?.Segment)}
                    />
                    <div className='pr'>
                      <Form.Control
                        type="text"
                        id="segCode"
                        name="segCode"
                        className="txt-input segment1"
                        placeholder=""
                        readOnly
                        value={unspscCodeData?.SegmentCode || ""}
                      // onChange={handleInput}
                      />
                      <div className='copy-icon'>
                        <img src={copyIcon} onClick={() => copyText("segCode")} alt="" />
                      </div>
                    </div>
                    <div className="txt-dipsplay">
                      <Form.Control
                        type="text"
                        id="segText"
                        name="segText"
                        className="txt-input segment1"
                        placeholder=""
                        readOnly
                        value={unspscCodeData?.Segment || ""}
                      // onChange={handleInput}
                      />
                      <div className='copy-icon'>
                        <img src={copyIcon} alt="" onClick={() => copyText("segText")} />
                      </div>
                    </div>
                  </div>
                  <div className='segment-code-txt-div'>
                    <Form.Check
                      inline
                      label=""
                      name="group1"
                      type="radio"
                      checked={selectedSegment === "family"}
                      id={`inline-radio-2`}
                      disabled={Object.keys(unspscCodeData).length === 0}
                      onChange={() => handleCodeRadio("family", "segment2", unspscCodeData?.FamilyCode, unspscCodeData?.Family)}
                    />
                    <div className='pr'>
                      <Form.Control
                        type="text"
                        id="famCode"
                        name="famCode"
                        className="txt-input segment2"
                        placeholder=""
                        readOnly
                        value={unspscCodeData?.FamilyCode || ""}
                      // onChange={handleInput}
                      />
                      <div className='copy-icon'>
                        <img src={copyIcon} onClick={() => copyText("famCode")} alt="" />
                      </div>
                    </div>
                    <div className="txt-dipsplay">
                      <Form.Control
                        type="text"
                        id="famText"
                        name="famText"
                        className="txt-input segment2"
                        placeholder=""
                        readOnly
                      value={unspscCodeData?.Family || ""}
                      // onChange={handleInput}
                      />
                      <div className='copy-icon'>
                        <img src={copyIcon} onClick={() => copyText("famText")} alt="" />
                      </div>
                    </div>
                  </div>
                  <div className='segment-code-txt-div'>
                    <Form.Check
                      inline
                      label=""
                      name="group1"
                      type="radio"
                      checked={selectedSegment === "class"}
                      id={`inline-radio-3`}
                      disabled={Object.keys(unspscCodeData).length === 0}
                      onChange={() => handleCodeRadio("class", "segment3", unspscCodeData?.ClassCode, unspscCodeData?.Class)}
                    />
                    <div className='pr'>
                      <Form.Control
                        type="text"
                        id="clsCode"
                        name="clsCode"
                        className="txt-input segment3"
                        placeholder=""
                        readOnly
                      value={unspscCodeData?.ClassCode || ""}
                      // onChange={handleInput}
                      />
                      <div className='copy-icon'>
                        <img src={copyIcon} onClick={() => copyText("clsCode")} alt="" />
                      </div>
                    </div>
                    <div className="txt-dipsplay">
                      <Form.Control
                        type="text"
                        id="clsText"
                        name="clsText"
                        className="txt-input segment3"
                        placeholder=""
                        readOnly
                      value={unspscCodeData?.Class || ""}
                      // onChange={handleInput}
                      />
                      <div className='copy-icon'>
                        <img src={copyIcon} onClick={() => copyText("clsText")} alt="" />
                      </div>
                    </div>
                  </div>
                  <div className='segment-code-txt-div'>
                    <Form.Check
                      inline
                      label=""
                      name="group1"
                      type="radio"
                      checked={selectedSegment === "comoddity"}
                      id={`inline-radio-4`}
                      disabled={Object.keys(unspscCodeData).length === 0}
                      onChange={() => handleCodeRadio("comoddity", "segment4", unspscCodeData?.CommodityCode, unspscCodeData?.Commodity)}
                    />
                    <div className='pr'>
                      <Form.Control
                        type="text"
                        id="comCode"
                        name="comCode"
                        className="txt-input segment4"
                        placeholder=""
                        readOnly
                      value={unspscCodeData?.CommodityCode || ""}
                      // onChange={handleInput}
                      />
                      <div className='copy-icon'>
                        <img src={copyIcon} onClick={() => copyText("comCode")} alt="" />
                      </div>
                    </div>
                    <div className="txt-dipsplay">
                      <Form.Control
                        type="text"
                        id="comText"
                        name="comText"
                        className="txt-input segment4"
                        placeholder=""
                        readOnly
                      value={unspscCodeData?.Commodity || ""}
                      // onChange={handleInput}
                      />
                      <div className='copy-icon'>
                        <img src={copyIcon} onClick={() => copyText("comText")} alt="" />
                      </div>
                    </div>
                  </div>

                </div>
                <div className='segment-section' ref={segmentDiv}>
                  <div>
                    <h6 className='filter-lable'>Selected Details</h6>
                  </div>
                  <div className='segment-code-txt-div'>
                  <div className="full-txt-dipsplay">
                      <Form.Control
                        type="text"
                        id="fullText"
                        name="segText"
                        className={`txt-input ${selectedClass}`}
                        placeholder=""
                        readOnly
                        value={selectedCode + " " + selectedText || ""}
                      // onChange={handleInput}
                      />
                      <div className='copy-icon'>
                        <img src={copyIcon} alt="" onClick={() => copyText("fullText")} />
                      </div>
                    </div>
                  </div>
                </div>

                <div className='category-section'>
                  <div ref={categoryHead}>
                    <h6 className='filter-lable'>Category Definition</h6>
                  </div>
                  <div className='category-txt-div' id="category-text">
                      {unspscCodeData?.CategoryDefinition || ""}
                    <div className='copy-icon'>
                      <img src={copyIcon} onClick={copyDivText} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default UNSPSCPage;