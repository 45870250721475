import React, { useEffect, useState } from 'react';
import SideBarMenu from '../SideBarMenu/SideBarMenu';
import { Col, Row, Form } from 'react-bootstrap';
import TopBarMenu from '../TopBar/TopBarMenu';
import { HCOnClickSubmitButton, HCSearchButton } from '../HCButtons/HCButtons';

import copyIcon from '../../Images/icons/copy_icon.svg';
import { toast } from 'react-toastify';
import Loader from "react-spinner-loader";
import * as apiHeader from '../../Const/apiHeaders';
import { useDispatch } from 'react-redux';
import { getChildrenData, getParentsData, getSnomedData, getSynonmsData, loadChildrenData, loadFilteredSNOMEDData, loadParentsData, loadSynonymsData } from '../../redux/serviceAction';
import { useSelector } from 'react-redux';
import ConceptTable from './ConceptTable';
import SynonymTable from './SynonymTable';
import ParentTable from './ParentTable';
import ChildrenTable from './ChildrenTable';
import { useNavigate } from 'react-router-dom';

function SnomedSearcher({ menuCollapse, menuIconClick }) {

    let dispatch = useDispatch();

    // data from redux store
  const {snomedData} = useSelector(state => state.data);
  const {synonymData} = useSelector(state => state.data);
  const {parentData} = useSelector(state => state.data);
  const {childrenData} = useSelector(state => state.data);

    // #region set Initian states
  const initialState = {
    SearchText1: "", SearchText2: "",
    SearchText3: "", SearchText4: ""
  };

  useEffect(() => {
    dispatch(getSnomedData([]))
    dispatch(getSynonmsData([]))
    dispatch(getParentsData([]))
    dispatch(getChildrenData([]))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [loader, setLoader] = useState(false);
  const [loaderMsg, setLoaderMsg] = useState("")

  const [initSearchValues, setInitSearchValues] = useState(initialState);

    // #region handle inputs
    const handleInput = (e) => {
        const {name, value} = e.target;
        setInitSearchValues({...initSearchValues, [name]: value})
      }
    // #endregion

    // #region onRow Seletion
  const [conceptRow, setConceptRow] = useState('')
  const onConceptRowSelection = (index) => {
    setConceptRow(index)
  }

  const [snomedResultString, setSnomedResultString] = useState('')
  const onRowDoubleClick = (row) => {
    dispatch(getSynonmsData([]))
    dispatch(getParentsData([]))
    dispatch(getChildrenData([]))
    setLoader(true)
    setLoaderMsg("Please wait while loading Synonyms, Parent and Children")
    if (row.ConceptId && row.Term) {
        let ResultString =
            "Concept ID : " + row.ConceptId + " And Term : " + row.Term;
            setSnomedResultString(ResultString)
            dispatch(loadSynonymsData(row.ConceptId))
            dispatch(loadParentsData(row.ConceptId))
            dispatch(loadChildrenData(row.ConceptId, setLoader))
    }
  }

  const [synonymRowIndex, setSynonymRowIndex] = useState('')
  const onSynonymRowSelection = (index) => {
    setSynonymRowIndex(index)
  }
  const [parentRowIndex, setParentRowIndex] = useState('')
  const onParentRowSelection = (index) => {
    setParentRowIndex(index)
  }
  const [childrenRowIndex, setChildrenRowIndex] = useState('')
  const onChildrenRowSelection = (index) => {
    setChildrenRowIndex(index)
  }
  // #endregion

  // #region Main filter function for synonyms, parent and children
  // #region handle table data filter for synonyms, parent and children
  const [filteredConcepts, setFilteredConcepts] = useState([])
  const [conceptFilterValue, setConceptFilterValue] = useState('')
  const handleConceptSearch = (e) => {
    const filteredData = filterData(
      e.target.value,
      snomedData,
      columns
    );
    setConceptFilterValue(e.target.value);
    setFilteredConcepts(filteredData);
  };

  const [filteredSynonyms, setFilteredSynonyms] = useState([])
  const [synonymFilterValue, setSynonymFilterValue] = useState('')
  const handleSynonymSearch = (e) => {
    const filteredData = filterData(
      e.target.value,
      synonymData,
      synonymColumns
    );
    setSynonymFilterValue(e.target.value);
    setFilteredSynonyms(filteredData);
  };

  const [filteredParents, setFilteredParents] = useState([])
  const [parentFilterValue, setParentFilterValue] = useState('')
  const handleParentSearch = (e) => {
    const filteredData = filterData(
      e.target.value,
      parentData,
      parentColumns
    );
    setParentFilterValue(e.target.value);
    setFilteredParents(filteredData);
  };
  
  const [filteredChildren, setFilteredChildren] = useState([])
  const [childrenFilterValue, setChildrenFilterValue] = useState('')
  const handleChildrenSearch = (e) => {
    const filteredData = filterData(
      e.target.value,
      childrenData,
      childrenColumns
    );
    setChildrenFilterValue(e.target.value);
    setFilteredChildren(filteredData);
  };

  const filterData = (searchText, dataSearch, columns) => {
    if (!searchText) {
      return dataSearch; // Return all data if search text is empty
    }

    const lowerSearchText = searchText.toLowerCase();
    return dataSearch.filter((snomedData) =>
      columns.some((column) =>
        String(snomedData[column.id])
          .toLowerCase()
          .includes(lowerSearchText)
      )
    );
  };
  // #endregion Main filter function for synonyms, parent and children

  // #region on enter key press
//   document.addEventListener('keypress', function(event) {
//     if (event.key === 'Enter') {
//         searchFilter()
//     }
// });
// #endregion

    // #region handle search button
  const searchFilter = (e) => {
    // if (!isLockVersion) {
    //     toast.error("Please lock the version to continue..", { autoClose: true });
    //     return;
    //   }
    e.preventDefault()
      if (
        !(initSearchValues.SearchText1 ||
            initSearchValues.SearchText2 ||
            initSearchValues.SearchText3 ||
            initSearchValues.SearchText4)
      ) {
        toast.error("Please enter atleast one search field", { autoClose: 3000 });
        return;
      }
      setLoader(true)
      setLoaderMsg("Please wait while loading data")
      dispatch(getSynonmsData([]))
      dispatch(getParentsData([]))
      dispatch(getChildrenData([]))
      setSnomedResultString('')
      filterDataApi(initSearchValues);
  }
  // #endregion

  // #region Api call
  const filterDataApi = (SearchValues) => {
    let userName = sessionStorage.getItem("userName")
    SearchValues.UserName = userName
    let postRequest  = {
      method: "POST",
      mode: "cors",
      headers: apiHeader.headerContent,
      body: JSON.stringify(SearchValues),
    };
    
    dispatch(loadFilteredSNOMEDData(postRequest, setLoader))
  }
  // #endregion
  
  // #region copy text function
  const copyText = (id) => {
    // const text = document.getElementById('segCode').innerText;
    const text = document.getElementById(id).value;
    navigator.clipboard.writeText(text)
        .then(() => toast.success('Text copied!', {
          autoClose: 3000 
      }))
        .catch(err => console.error('Error copying text: ', err));
};
// #endregion

    // #region lock version btn
    const [isLockVersion, setIsLockVersion] = useState(false)
    const lockTheVersion = () => {
        setIsLockVersion(!isLockVersion)
    }
    // #endregion
    
    // #region clear filter function
    const clearFilter = () => {
        setInitSearchValues(initialState);
        setSnomedResultString("");
        setSynonymRowIndex('');
        setParentRowIndex('');
        setChildrenRowIndex('');
        setConceptFilterValue('');
        setSynonymFilterValue('');
        setParentFilterValue('');
        setChildrenFilterValue('');
        dispatch(getSnomedData([]))
        dispatch(getSynonmsData([]))
        dispatch(getParentsData([]))
        dispatch(getChildrenData([]))
    }
    // #endregion

    // Columns definition for react-table

    const columns = [
        { id: 'Active', label: 'Active', minWidth: 50 },
        { id: 'IsFSN', label: 'Is FSN?', minWidth: 50 },
        {
            id: 'ConceptId',
            label: 'Concept Id',
            minWidth: 100,
        },
        {
            id: 'Term',
            label: 'Term',
            minWidth: 250,
        }
    ];

    const synonymColumns = [
        { id: 'ConceptId', label: 'ConceptId', minWidth: 50 },
        {
            id: 'Term',
            label: 'Term',
            minWidth: 'max-content',
        }
    ];

    const parentColumns = [
        { id: 'DestinationID', label: 'Destination Id', minWidth: 50 },
        {
            id: 'DestinationIDName',
            label: 'Destination ID Name',
            minWidth: 300,
        }
    ];

    const childrenColumns = [
        { id: 'SourceId', label: 'Source Id', minWidth: 50 },
        {
            id: 'SourceIDName',
            label: 'Source ID Name',
            minWidth: 300,
        }
    ];
    // #endregion

    let navigate = useNavigate();
    const gotoPreviousPage = () => {
        navigate(-1)
    }

    return (
        <>
            <Loader show={loader} type="box" message={loaderMsg} />
            <SideBarMenu menuCollapse={menuCollapse} menuIconClick={menuIconClick} />
            <div className='main-conent-div'>
                <Row className='main-div-row'>
                    <Col md={8}>
                        <div className='page-header-btn-div'>
                            <div className='hc-main-header'>
                                <i className="fa fa-chevron-left" onClick={gotoPreviousPage} aria-hidden="true"></i><span>SNOMED</span>
                            </div>
                            <div className='filter-clear-btn'>
                                {HCOnClickSubmitButton("Clear All", clearFilter)}
                            </div>
                        </div>
                    </Col>
                    <Col md={4}>
                        <TopBarMenu />
                    </Col>
                </Row>
                <Row className='search-row'>
                    <Col md={8} className='search-row-col'>
                        <div className='filter-section'>
                            <div className='filter-div'>
                                <Form onSubmit={searchFilter}>
                                {/* <Row>
                                    <Col md={5}>
                                        <div className="input-div">
                                            <div className='version-lable-div'>
                                                <Form.Label className='filter-lable'>Version</Form.Label>
                                                <Form.Label className='latest-version-lable'>Latest_Version</Form.Label>
                                            </div>
                                            <Select
                                                className="txt-input"
                                                classNamePrefix="react-select-list"
                                                // options={subscriptionProduct}
                                                placeholder=""
                                                name="product"
                                                // value={
                                                //     subscriptionProduct.find(
                                                //         (option) => option.value === initStates.product
                                                //     ) || ""
                                                // }
                                                // onInputChange={subscriptionProduct.label}
                                                // onChange={(data) => handleSelect(data.value, "product")}
                                                isSearchable={true}
                                            />
                                        </div>
                                    </Col>
                                    <Col md={1}>
                                        {HCToggleButton("Lock", lockTheVersion, isLockVersion)}
                                    </Col>
                                </Row> */}
                                <Row>
                                    <Col md={11}>
                                        <Row className='m-0 p-0'>
                                        <h6 className='filter-lable'>Search SNOMED Details</h6>
                                            <Col md={3} className='m-0 p-0'>
                                                <div className="input-div">
                                                    
                                                    <Form.Control
                                                        type="text"
                                                        name="SearchText1"
                                                        className="form-control txt-input"
                                                        placeholder=""
                                                    value={initSearchValues.SearchText1}
                                                    onChange={handleInput}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md={3} className='m-0'>
                                                <div className="input-div">
                                                    <Form.Control
                                                        type="text"
                                                        name="SearchText2"
                                                        className="form-control txt-input"
                                                        placeholder=""
                                                    value={initSearchValues.SearchText2}
                                                    onChange={handleInput}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md={3} className='m-0'>
                                                <div className="input-div">
                                                    <Form.Control
                                                        type="text"
                                                        name="SearchText3"
                                                        className="form-control txt-input"
                                                        placeholder=""
                                                    value={initSearchValues.SearchText3}
                                                    onChange={handleInput}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md={3} className='m-0'>
                                                <div className="input-div">
                                                    <Form.Control
                                                        type="text"
                                                        name="SearchText4"
                                                        className="form-control txt-input"
                                                        placeholder=""
                                                    value={initSearchValues.SearchText4}
                                                    onChange={handleInput}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md={1}>
                                        <div className="input-div">
                                            {HCSearchButton()}
                                        </div>
                                    </Col>
                                </Row>
                                </Form>
                            </div>
                        </div>
                    </Col>
                    <Col md={4} className='search-row-col'>
                        <div className='code-details-div'>
                            <div className='result-display-section'>
                                <div className='result-display-div'>
                                    <div>
                                        <h6 className='filter-lable'>Showing Result For</h6>
                                    </div>
                                    <div className='result-code-txt-div'>
                                        <div className="txt-dipsplay">
                                            <Form.Control
                                                type="text"
                                                id="resultString"
                                                name="resultString"
                                                className="txt-input resulttxt"
                                                placeholder=""
                                                readOnly
                                            value={snomedResultString}
                                            // onChange={handleInput}
                                            />
                                            <div className='copy-icon'>
                                                <img src={copyIcon} onClick={() => copyText("resultString")} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className='search-row'>
                    <Col md={6} className='search-row-col'>
                        <div className='filter-section'>
                            <div className='table-div'>
                                <div className='table-header-filter'>
                                    <div>
                                        <h6 className='filter-lable'>Concepts</h6>
                                    </div>
                                    <div className="search-input">
                                        <Form.Control
                                            type="text"
                                            name="username"
                                            className="txt-input searchTxt"
                                            placeholder=""
                                            value={conceptFilterValue}
                                        onChange={handleConceptSearch}
                                        />
                                        <div className='copy-icon'>
                                            {HCSearchButton(lockTheVersion)}
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className='snomed-table-section'>
                                <ConceptTable columns={columns} data={conceptFilterValue ? filteredConcepts : snomedData}
                                onRowSelection={onConceptRowSelection} onRowDoubleClick={onRowDoubleClick} conceptRow={conceptRow} />
                                </div>
                                <p className='note-text'>Note: Double-click to view Synonyms, Parent and, Children</p>
                            </div>
                        </div>
                    </Col>
                    <Col md={6} className='search-row-col'>
                        <div className='filter-section'>
                            <div className='table-div'>
                                <div className='table-header-filter'>
                                    <div>
                                        <h6 className='filter-lable'>Synonyms</h6>
                                    </div>
                                    <div className="search-input">
                                        <Form.Control
                                            type="text"
                                            name="username"
                                            className="txt-input searchTxt"
                                            placeholder=""
                                        value={synonymFilterValue}
                                            onChange={handleSynonymSearch}
                                        />
                                        <div className='copy-icon'>
                                            {HCSearchButton(lockTheVersion)}
                                        </div>
                                    </div>
                                </div><div className='snomed-table-section'>
                                <SynonymTable columns={synonymColumns} data={synonymFilterValue ? filteredSynonyms : synonymData}
                                onRowSelection={onSynonymRowSelection} synonymRowIndex={synonymRowIndex} />
                                </div>                            
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className='search-row'>
                    <Col md={6} className='search-row-col'>
                        <div className='filter-section'>
                            <div className='table-div'>
                                <div className='table-header-filter'>
                                    <div>
                                        <h6 className='filter-lable'>Parent</h6>
                                    </div>
                                    <div className="search-input">
                                        <Form.Control
                                            type="text"
                                            name="username"
                                            className="txt-input searchTxt"
                                            placeholder=""
                                        value={parentFilterValue}
                                        onChange={handleParentSearch}
                                        />
                                        <div className='copy-icon'>
                                            {HCSearchButton(lockTheVersion)}
                                        </div>
                                    </div>
                                </div>
                                <div className='snomed-table-section'>
                                <ParentTable columns={parentColumns} data={parentFilterValue ? filteredParents : parentData}
                                onRowSelection={onParentRowSelection} parentRowIndex={parentRowIndex} />
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col md={6} className='search-row-col'>
                        <div className='filter-section'>
                            <div className='table-div'>
                                <div className='table-header-filter'>
                                    <div>
                                        <h6 className='filter-lable'>Children</h6>
                                    </div>
                                    <div className="search-input">
                                        <Form.Control
                                            type="text"
                                            name="username"
                                            className="txt-input searchTxt"
                                            placeholder=""
                                        value={childrenFilterValue}
                                        onChange={handleChildrenSearch}
                                        />
                                        <div className='copy-icon'>
                                            {HCSearchButton(lockTheVersion)}
                                        </div>
                                    </div>
                                </div>
                                <div className='snomed-table-section'>
                                <ChildrenTable columns={childrenColumns} data={childrenFilterValue ? filteredChildren : childrenData}
                                onRowSelection={onChildrenRowSelection} childrenRowIndex={childrenRowIndex} />
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default SnomedSearcher;