import { Routes, Route } from "react-router-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import "react-toastify/dist/ReactToastify.css";
import { useEffect, useState } from "react";

// #region local import
import './App.css';
import './Css/common.scss';
import UNSPSCPage from "./Component/UNSPSC/UNSPSCPage";
import SubscriberList from "./Component/Subscribers/SubscriberList";
import SnomedSearcher from "./Component/SNOMED/SnomedSearcher";
import Login from "./Login";
import ViewSubscription from "./Component/Subscribers/ViewSubscription";
import ChangePassword from "./Component/ChangePassword";
import ResetPassword from "./ResetPassword";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { loadLoginSubscription } from "./redux/serviceAction";
import AccessDenaid from "./Component/AccessDenied";

function App() {

  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  let userName = sessionStorage.getItem("userName");
  let dispatch = useDispatch();
  const {loginSubscription} = useSelector(state => state.data);
  console.log(loginSubscription)

  useEffect(() => {
    dispatch(loadLoginSubscription(userName))
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
      setScreenWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // #region menubar collapse function
  const [menuCollapse, setMenuCollapse] = useState(false)

  const menuIconClick = () => {
    menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);
  };
  // #region

  const [accessPages, setAccessPages] = useState([])
  useEffect(() => {
    if(Object.keys(loginSubscription).length !== 0 &&loginSubscription.PageNames.length !== 0){
      let accessedPages = loginSubscription.PageNames.map(element => {
        return element.ProductPageName
      })
      setAccessPages(accessedPages);
    }
  }, [loginSubscription])

  let isLogin = sessionStorage.getItem("isLogin")

  return (
    <div className="App" style={{ height: windowHeight, width: "100%", overflow: "auto" }}>
      {isLogin ? 
        <Routes>
          <Route path="/" element={accessPages.includes('UNSPSC Searcher') ? <UNSPSCPage menuCollapse={menuCollapse} menuIconClick={menuIconClick} screenWidth={screenWidth} /> :
        (!accessPages.includes('UNSPSC Searcher') && accessPages.includes('SNOMED Searcher')) ? <SnomedSearcher menuCollapse={menuCollapse} menuIconClick={menuIconClick} screenWidth={screenWidth} /> :
        (!accessPages.includes('UNSPSC Searcher') && !accessPages.includes('SNOMED Searcher') && accessPages.includes('Subscription')) && <SubscriberList menuCollapse={menuCollapse} menuIconClick={menuIconClick} screenWidth={screenWidth} />} />
          <Route path="/login" element={accessPages.includes('UNSPSC Searcher') ? <UNSPSCPage menuCollapse={menuCollapse} menuIconClick={menuIconClick} screenWidth={screenWidth} /> : <AccessDenaid />} />
          <Route path="/UNSPSC_Searcher" element={accessPages.includes('UNSPSC Searcher') ? <UNSPSCPage menuCollapse={menuCollapse} menuIconClick={menuIconClick} screenWidth={screenWidth} /> : <AccessDenaid />} />
          <Route path="/SNOMED_Searcher" element={accessPages.includes('SNOMED Searcher') ? <SnomedSearcher menuCollapse={menuCollapse} menuIconClick={menuIconClick} screenWidth={screenWidth} /> : <AccessDenaid />} />
          <Route path="/Subscription" element={accessPages.includes('Subscription') ? <SubscriberList menuCollapse={menuCollapse} menuIconClick={menuIconClick} screenWidth={screenWidth} /> : <AccessDenaid />} />
          <Route path="/changePassowrd" element={<ChangePassword menuCollapse={menuCollapse} menuIconClick={menuIconClick} screenWidth={screenWidth} />} />
          <Route path="/Subscribers/:id" element={accessPages.includes('Subscription') ? <ViewSubscription menuCollapse={menuCollapse} menuIconClick={menuIconClick} screenWidth={screenWidth} /> : <AccessDenaid />} />
        </Routes>
        :
        <Routes>
          <Route path="/ResetPassword" element={<ResetPassword />} />
          <Route path="*" element={<Login  />} />
          <Route path="/login" element={<Login />} />
        </Routes>
      }
    </div>
  );
}

export default App;