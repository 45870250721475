import React from 'react'
import { Modal,Form } from 'react-bootstrap';
import { HCOnClickSubmitButton } from '../HCButtons/HCButtons'

function LogOutModal({openModal, closeLogoutModal, logOut}) {
  return (
    <Modal
        show={openModal}
        onHide={closeLogoutModal}
        className=""
      >
        {/* <div className="modal-header">
          <h6 className="modal-header-text">
            Reset Password
          </h6>
        </div> */}
        <div>
          <div className="logout-text">
            <div className="input-div">
              <h6>Are you sure want to logout from the session.?</h6>
              
            </div>
          </div>
          <div className="btn-submit-cancel">
            <div className='btn-save'>
              {HCOnClickSubmitButton("Yes, Logout", logOut)}
            </div>
            <div className='btn-cancel'>
              {HCOnClickSubmitButton("No, Cancel", closeLogoutModal)}
            </div>
          </div>
        </div>
      </Modal>
  )
}

export default LogOutModal;
