import * as types from './serviceType';
import * as baseURL from '../Const/const';
import * as apiHeader from '../Const/apiHeaders';
import { toast } from "react-toastify";
import http from "../Component/http-common";

let userName = sessionStorage.getItem("userName");
// #region set payload
// #region Login service action
const postLogin = () => ({
    type: types.Post_Login,
});

// #region Subscription page service action
const getSubscriptionList = (data) => ({
    type: types.Get_Subscription_List,
    payload: data
});
const getCountryList = (data) => ({
    type: types.Get_Country,
    payload: data
});
const getStatesList = (data) => ({
    type: types.Get_States,
    payload: data
});
const getRolesList = (data) => ({
    type: types.Get_Roles,
    payload: data
});
const getPageList = (data) => ({
    type: types.Get_Pages,
    payload: data
});
const getSingleSubscription = (data) => ({
    type: types.Get_Single_Subscription,
    payload: data
})
const getLoginSubscription = (data) => ({
    type: types.Get_SunscriptionBy_User,
    payload: data
})

// #region unspsc page service action
const getUnspscVersionList = (data) => ({
    type: types.Get_Unspsc_Versions,
    payload: data
});
export const getUnspscData = (data) => ({
    type: types.Get_Unspsc_Data,
    payload: data
})
export const getUnspscCodeData = (data) => ({
    type: types.Get_Unspsc_Code_Data,
    payload: data
})

// #region unspsc page service action
export const getSnomedData = (data) => ({
    type: types.Get_Snomed_Data,
    payload: data
})
export const getSynonmsData = (data) => ({
    type: types.Get_Synonym_Data,
    payload: data
})
export const getParentsData = (data) => ({
    type: types.Get_Parent_Data,
    payload: data
})
export const getChildrenData = (data) => ({
    type: types.Get_Children_Data,
    payload: data
})
// #endregion

// #region call api service
// #region Login service call
export const loadPostLogin = (requestData, navigateToDashboRd, UserName) => {
    return function (dispatch) {
        fetch(`${baseURL.apiUrl}/login/ValidateLogin`, requestData)
        .then((resp) => resp.json())
        .then((resp) => {
            if(resp === "Success"){
                sessionStorage.setItem("isLogin", true);
                sessionStorage.setItem("userName", UserName);
                dispatch(postLogin());
                navigateToDashboRd();
                window.location.reload();
                
            } else {
                toast.error(resp.Message);
            }
        })
        .catch((err) => {
            console.log(err, "resp")
            // toast.error('Please enter username and password');
        })
    }
};

export const sendResetPasswordLink = (userEmail, postRequest) => {
    return function (dispatch) {
        fetch(`${baseURL.apiUrl}/login/ResetPassword?Username=${userEmail}`, postRequest)
        .then((resp) => resp.json())
        .then((resp) => {
            if(resp === "Success"){
                toast.success(`Link has been sent to the email Id ${userEmail}`, {
                    autoClose: 3000
                  })
            } else {
                toast.error(resp.Message)
            }
        })
        .catch((err) => {
            console.log(err, "resp");
        })
    }
}

export const loadChangePassword = (postRequest,logOut) => {
    return function (dispatch) {
        fetch(`${baseURL.apiUrl}/login/ChangePassword`, postRequest)
        .then((resp) => resp.json())
        .then((resp) => {
            if(resp === "SUCCESS"){
                toast.success(`Password updated successfully`, {
                    autoClose: 3000
                  })
                  logOut();
            } else {
                toast.error(resp.Message)
            }
        })
        .catch((err) => {
            console.log(err, "resp");
        })
    }
}

export const loadResetPassword = (postRequest, navigate) => {
    return function (dispatch) {
        fetch(`${baseURL.apiUrl}/login/ChangePasswordUsingPasswordResetLink`, postRequest)
        .then((resp) => resp.json())
        .then((resp) => {
            console.log(resp, "resp")
            if(resp === "Success"){
                toast.success(`Password updated successfully`, {
                    autoClose: 3000
                  })
                  navigate()
            } else {
                toast.error(resp.Message)
            }
        })
        .catch((err) => {
            console.log(err, "resp");
        })
    }
}

// #region Subscripion service call
export const loadGetSubscriptionList = (userName, setLoader) => {
    return function (dispatch) {
        fetch(`${baseURL.apiUrl}/Subscription/ReadSubscriptions?UserName=${userName}`, apiHeader.getRequestOptions)
        .then((resp) => resp.json())
        .then((resp) => {
            dispatch(getSubscriptionList(resp))
            setLoader(false)
        })
        .catch((err) => {
            console.log(err, "resp")
            // toast.error('Please enter username and password');
        })
    }
};

export const loadFilteredSubscriptionList = (filterType, searchValue, setLoader) => {
    return function (dispatch) {
        fetch(`${baseURL.apiUrl}/Subscription/ReadSubsciptionsBySearchFieldAndSearchText?SearchText=${searchValue}&SearchOn=${filterType}`, apiHeader.getRequestOptions)
        .then((resp) => resp.json())
        .then((resp) => {
            dispatch(getSubscriptionList(resp))
            setLoader(false)
        })
        .catch((err) => {
            console.log(err, "resp")
            // toast.error('Please enter username and password');
        })
    }
};

export const loadCountryList = () => {
    return function (dispatch) {
        fetch(`${baseURL.apiUrl}/Subscription/ReadSubscriptionCountries`, apiHeader.getRequestOptions)
        .then((resp) => resp.json())
        .then((resp) => {
            dispatch(getCountryList(resp.map((data) => {
                return { value: data, label: data };
              })))
        })
        .catch((err) => {
            console.log(err, "resp")
            // toast.error('Please enter username and password');
        })
    }
};

export const loadStatesList = (country) => {
    return function (dispatch) {
        fetch(`${baseURL.apiUrl}/Subscription/ReadSubscriptionStates?Country=${country}`, apiHeader.getRequestOptions)
        .then((resp) => resp.json())
        .then((resp) => {
            dispatch(getStatesList(resp.map((data) => {
                return { value: data, label: data };
              })))
        })
        .catch((err) => {
            console.log(err, "resp")
            // toast.error('Please enter username and password');
        })
    }
};

export const loadRolesList = () => {
    return function (dispatch) {
        fetch(`${baseURL.apiUrl}/role/ReadRoles`, apiHeader.getRequestOptions)
        .then((resp) => resp.json())
        .then((resp) => {
            dispatch(getRolesList(resp.map((data) => {
                return { value: data.RoleName, label: data.RoleName };
              })))
        })
        .catch((err) => {
            console.log(err, "resp")
            // toast.error('Please enter username and password');
        })
    }
};

export const loadPageList = () => {
    return function (dispatch) {
        fetch(`${baseURL.apiUrl}/page/ReadActivePages`, apiHeader.getRequestOptions)
        .then((resp) => resp.json())
        .then((resp) => {
            dispatch(getPageList(resp.map((data) => {
                return { value: data.PageName, label: data.PageName };
              })))
        })
        .catch((err) => {
            console.log(err, "resp")
            // toast.error('Please enter username and password');
        })
    }
};

export const loadImageSubscription = (formData, setInitStates, initStates) => {
    return function (dispatch) {
        http.post("/Subscription/uploadfile", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }).then((resp) => {
            if(resp.statusText === "Created"){
                setInitStates({...initStates, "TempImageFileName": resp.data})
            } else {
                toast.error(resp.Message)
            }
          }).catch((err) => {
            console.log(err, "resp")
            // toast.error('Please enter username and password');
        })
    }
}

export const loadPostSubscription = (postData, closeModal, setLoader) => {
    return function (dispatch) {
        fetch(`${baseURL.apiUrl}/Subscription/CreateSubscription`, postData)
        .then((resp) => resp.json())
        .then((resp) => {
            if(!resp.Message){
            let response = resp.split("|");
            if(response[0] === "Success") {
            toast.success(`Subscription created successfully`, {
                autoClose: 3000
              })
            dispatch(loadGetSubscriptionList(userName, setLoader))
            closeModal();
            }} else {
                toast.error(resp.Message);
            }
        }).catch((err) => {
            // toast.error(resp.Message);
            console.log(err, "resp")
            // toast.error('Please enter username and password');
        })
    }
}

export const loadSingleSubscription = (Subscription) => {
    return function (dispatch) {
        fetch(`${baseURL.apiUrl}/Subscription/ReadSubscriptionById?id=${Subscription}`, apiHeader.getRequestOptions)
        .then((resp) => resp.json())
        .then(resp => {
            dispatch(getSingleSubscription(resp))
        }).catch((err) => {
            console.log(err, "resp")
            // toast.error('Please enter username and password');
        })
    }

}
export const loadLoginSubscription = (userName) => {
    return function (dispatch) {
        fetch(`${baseURL.apiUrl}/Subscription/ReadSubscriptionByUserName?UserName=${userName}`, apiHeader.getRequestOptions)
        .then((resp) => resp.json())
        .then(resp => {
            dispatch(getLoginSubscription(resp))
        }).catch((err) => {
            console.log(err, "resp")
            // toast.error('Please enter username and password');
        })
    }

}

export const loadUpdateSubscription = (postData, closeModal, setLoader) => {
    return function (dispatch) {
        fetch(`${baseURL.apiUrl}/Subscription/UpdateSubscription`, postData)
        .then((resp) => resp.json())
        .then((resp) => {
            if(resp === "Success") {
            toast.success(`Subscription updated successfully`, {
                autoClose: 3000
              })
            dispatch(loadGetSubscriptionList(userName, setLoader))
            closeModal();
            } else {
                toast.error(resp.Message);
            }
        }).catch((err) => {
            console.log(err)
            // toast.error('Please enter username and password');
        })
    }
}

export const displayProfileImage = (SubscriptionID, profileFileName) => {
    // Return the fetch promise
    return fetch(`${baseURL.apiUrl}/Subscription/downloadfile?id=${SubscriptionID}&FileName=${profileFileName}`, apiHeader.getRequestOptions)
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.blob();
        })
        .then(blob => {
            const fileURL = window.URL.createObjectURL(blob);
            return fileURL;
        });
}

// #region unspsc service call
export const loadUnspscVersions = (userName) => {
    return function (dispatch) {
        fetch(`${baseURL.apiUrl}/UNSPSC/ReadUNSPSCVersions?UserName=${userName}`, apiHeader.getRequestOptions)
        .then((resp) => resp.json())
        .then((resp) => {
            dispatch(getUnspscVersionList(resp.map((data) => {
                return { value: data.Version, label: data.Version };
              })))
        }).catch((err) => {
            console.log(err)
        })
    }
}

export const loadLatestVersion = (setInitSearchValues, initSearchValues, setLatestVersion) => {
    return function () {
        fetch(`${baseURL.apiUrl}/UNSPSC/GetUNSPSCLatestVersion`, apiHeader.getRequestOptions)
        .then((resp) => resp.json())
        .then((resp) => {
            setInitSearchValues({...initSearchValues, "TableName": resp});
            setLatestVersion(resp);
        }).catch((err) => {
            console.log(err)
        })
    }
}

export const loadFilteredUNSPSCData = (postRequest, setLoader) => {
    return function (dispatch) {
        fetch(`${baseURL.apiUrl}/UNSPSC/ReadUNSPSCSearchResult`, postRequest)
        .then((resp) => resp.json())
        .then((resp) => {
            dispatch(getUnspscData(resp))
            setLoader(false)
        }).catch((err) => {
            console.log(err)
        })
    }
}

export const loadUnspscCodeDetails = (version, code) => {
    return function (dispatch) {
        fetch(`${baseURL.apiUrl}/UNSPSC/ReadSegmentFamilyClassCommodity?TableName=${version}&Code=${code}`, apiHeader.getRequestOptions)
        .then((resp) => resp.json())
        .then((resp) => {
            dispatch(getUnspscCodeData(resp))
        }).catch((err) => {
            console.log(err)
        })
    }
}

// #region unspsc service call
export const loadFilteredSNOMEDData = (postRequest, setLoader) => {
    return function (dispatch) {
        fetch(`${baseURL.apiUrl}/snomedsearch/ReadConceptTerms`, postRequest)
        .then((resp) => resp.json())
        .then((resp) => {
            dispatch(getSnomedData(resp))
            setLoader(false)
        }).catch((err) => {
            console.log(err)
        })
    }
}

export const loadSynonymsData = (conceptId) => {
    return function (dispatch) {
        fetch(`${baseURL.apiUrl}/snomedsearch/ReadConceptSynonyms?ConceptID=${conceptId}`, apiHeader.getRequestOptions)
        .then((resp) => resp.json())
        .then((resp) => {
            dispatch(getSynonmsData(resp))
        }).catch((err) => {
            console.log(err)
        })
    }
}

export const loadParentsData = (conceptId) => {
    return function (dispatch) {
        fetch(`${baseURL.apiUrl}/snomedsearch/ReadConceptParents?ConceptID=${conceptId}`, apiHeader.getRequestOptions)
        .then((resp) => resp.json())
        .then((resp) => {
            dispatch(getParentsData(resp))
        }).catch((err) => {
            console.log(err)
        })
    }
}

export const loadChildrenData = (conceptId, setLoader) => {
    return function (dispatch) {
        fetch(`${baseURL.apiUrl}/snomedsearch/ReadConceptChildren?ConceptID=${conceptId}`, apiHeader.getRequestOptions)
        .then((resp) => resp.json())
        .then((resp) => {
            dispatch(getChildrenData(resp))
            setLoader(false)
        }).catch((err) => {
            console.log(err);
        })
    }
}
// #endregion