import React from 'react'

function AccessDenaid() {
  return (
    <div className='access-deneid-page'>
      <h4>User Does not have access to this page.</h4>
    </div>
  )
}

export default AccessDenaid
